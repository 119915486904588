import React, { useState, useEffect, useCallback } from "react";
import Header from "./../components/Layout/Header";
import InputSearch from "../components/Inputs/InputSearch";
import Breadcrumb from "../components/Breadcrumb";
import CompanyDetail from "../components/Store";
import { glossierDetail } from "../data/company-detail.js";
import localAuth from "../util/local-auth";
import { useRouter } from "../util/router";
import ModalWrapper from "../components/ModalWrapper";
import { Button, Select, Form, Upload, Input } from "antd";
import swal from "sweetalert";
import ImgCrop from "antd-img-crop";
import ImgUpload from "../assets/images/image-file-landscape-alternate.png";
import GlobalRatingForm from "../components/Forms/GlobalRatingForm";
import CategoryRatingForm from "../components/Forms/EditCategoryRating/CategoryRatingForm";

export default function Company() {
  const router = useRouter();
  const [company, setCompany] = useState({});
  const [ratings, setRatings] = useState([]);
  const [allCategories, setCategories] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [openEditGlobalRating, setOpenEditGlobalRating] = useState(false);
  const [openEditCategoryRating, setOpenEditCategoryRating] = useState(false);
  const [categorySelected, setCategorySelected] = useState("");
  const [hideSection, setHideSection] = useState(false);
  const arrayRatings = [];
  const [imageUrl, setImageUrl] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [form] = Form.useForm();
  const [categoryId, setCategoryId] = useState(false);
  const onOpenModal = () => {
    setOpenModal(true);
  };
  const onOpenEdit = () => {
    setOpenEdit(true);
  };
  const onOpenEditGlobalRating = () => {
    setOpenEditGlobalRating(true);
  };
  const onOpenEditCategoryRating = () => {
    setOpenEditCategoryRating(true);
  };
  const onCloseEditCategoryRating = () => {
    console.log("on close");
    setOpenEditCategoryRating(false);
  };
  const onCloseEditGlobalRating = () => {
    setOpenEditGlobalRating(false);
  };
  const getCategories = useCallback(async () => {
    let response = [];
    try {
      response = await localAuth.getCategories();

      if (response.success) {
        const categories = response.data.filter(
          (category) => category.name !== undefined
        );
        setCategories(categories);
      }
    } catch (error) {
      console.log({ error });
    }
  }, []);

  const getCompany = useCallback(async () => {
    try {
      const response = await localAuth.getCompany(router.query.id);

      if (response.success) {
        console.log("COMPANY:", response.data);
        let category = response.data.categories[0]?.name.toLowerCase();
        setCategorySelected(category);
        getCategoryRating(response.data.categories[0]?.id);
        setCompany(response.data);
      }
    } catch (error) {
      console.log("Error getting past events");
      console.log(error);
      return error;
    }
  }, [router]);

  const addCategoryAndRatingToCompany = async (values) => {
    console.log(values);
    // POST Data
    const data = {
      categoryRatings: values.rating,
      companyId: router.query.id,
    };

    try {
      const response = await localAuth.addCategoryAndRatingToCompany(data);

      if (response.success) {
        swal("Company was updated successfully", {
          icon: "success",
          buttons: false,
        });

        await getCompany();
      } else {
        swal(response.message, {
          icon: "error",
          buttons: false,
        });
      }
    } catch (error) {
      swal(
        "There was a problem on server, please try again in another moment.",
        {
          icon: "error",
          buttons: false,
        }
      );
    }

    //Close Modal
    setTimeout(() => {
      onCloseModal();
      swal.close();
    }, 2500);
  };

  const remove = () => {
    let remove = [];
    setImageUrl(false);
    setFileList(remove);
    setHideSection(false);
  };

  const onChange = ({ fileList: newFileList }) => {
    let image = newFileList[0]?.thumbUrl;
    setFileList(newFileList);
    setImageUrl(image);
    setHideSection(true);
  };

  const onCloseEdit = () => setOpenEdit(false);
  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  const getCategoryRating = async (id) => {
    try {
      const response = await localAuth.getCategoryRating(id);

      if (response.success) {
        console.log(response.data);
        setRatings(response.data);
      }
    } catch (error) {
      console.log("Error getting past events");
      console.log(error);
      return error;
    }
  };

  const addAdditionalRating = async (ratingName) => {
    const data = {
      categoryId: categoryId,
      name: ratingName,
    };

    try {
      const response = await localAuth.addAdditionalRating(data);

      if (response.success) {
        addRatingToCategory(response.data);
        arrayRatings.push(response.data);
      }
    } catch (error) {
      console.log("Error getting past events");
      console.log(error);
      return error;
    }
  };

  const onCloseModal = () => {
    form.resetFields();
    setOpenModal(false);
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCompany();
      getCategories();
    }
    return () => {
      isMounted = false;
    };
  }, [getCompany, getCategories]);

  const onFinish = (values) => {
    if (values) {
      addCategoryAndRatingToCompany(values);
    }
  };

  const onFinishEditCategoryRating = async (values) => {
    if (
      values["checkbox-group"] !== undefined &&
      values["checkbox-group"].length > 0
    ) {
      arrayRatings.push(...values["checkbox-group"]);
    }

    if (values.additional_certification) {
      await addAdditionalRating(values.additional_certification);
    }
    if (values.additional_certification_two) {
      await addAdditionalRating(values.additional_certification_two);
    }

    await editRatings();
  };

  const editRatings = async () => {
    const data = {
      companyId: router.query.id,
      categoryRatings: arrayRatings,
    };

    try {
      const response = await localAuth.editRatings(data);

      if (response.success) {
        console.log(response.data);
        onCloseEditCategoryRating();
        setCategorySelected("");
        getCompany();
      }
    } catch (error) {
      console.log("Error getting past events");
      console.log(error);
      onCloseEditCategoryRating();
      return error;
    }
  };
  const addRatingToCategory = async (ratingId) => {
    console.log(ratingId);
    const data = {
      categoryId: categoryId,
      ratingId: ratingId,
    };

    try {
      const response = await localAuth.addRatingToCategory(data);

      if (response.success) {
        console.log(response.data);
      }
    } catch (error) {
      console.log("Error getting past events");
      console.log(error);
      return error;
    }
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);

    form.setFieldsValue({
      rating: [],
    });

    getCategoryRating(value);
  };

  const handleChangeCategory = (value) => {
    let category = value[0].toLowerCase();

    let rating = value[1];

    getCategoryRating(rating);
    setCategoryId(rating);
    setCategorySelected(category);
  };

  const editCompany = async (values) => {
    // POST Data
    let data = {
      amazonUrl: values.amazon_url_edit,
      description: values.description_edit,
      headquarter: values.headquarters_edit,
      name: values.company_name_edit,
      id: parseInt(router.query.id),
    };

    if (imageUrl) {
      data.imageUrl = imageUrl;
    }

    try {
      const response = await localAuth.editCompany(data);

      if (response.success) {
        swal("Company was updated successfully", {
          icon: "success",
          buttons: false,
        });
        getCompany();
      } else {
        swal(response.message, {
          icon: "error",
          buttons: false,
        });
      }
      setTimeout(() => {
        swal.close();
        onCloseEdit();
      }, 2000);
    } catch (error) {
      swal(
        "There was a problem on server, please try again in another moment.",
        {
          icon: "error",
          buttons: false,
        }
      );
      setTimeout(() => {
        swal.close();
        onCloseEdit();
      }, 2000);
    }
  };
  const onFinishEdit = (values) => {
    if (values) {
      editCompany(values);
    }
  };
  const onFinishEditGlobalRatings = (values) => {
    if (values) {
      console.log({ values });
      swal({
        title: "Not implemented yet!",

        icon: "info",
        button: "ok",
      });
      setTimeout(() => {
        swal.close();
        onCloseEdit();
      }, 2000);
    }
  };
  return (
    <div>
      <Header>
        <InputSearch
          placeholder={"Search for products"}
          appearance="products"
        />
      </Header>
      <section className="container">
        <Breadcrumb
          push="/companies"
          prevRoute="Companies"
          currentRoute="Company Detail"
        />
        {Object.keys(company).length !== 0 && company.constructor !== {} && (
          <CompanyDetail
            companyDetail={glossierDetail}
            storeInfo={company}
            openModal={onOpenModal}
            openEdit={onOpenEdit}
            openEditGlobalRating={onOpenEditGlobalRating}
            openEditCategoryRating={onOpenEditCategoryRating}
          />
        )}
      </section>

      <ModalWrapper open={openModal} onCloseModal={onCloseModal}>
        <div className="company-detail-category">
          <h3>Add Category</h3>

          <Form
            form={form}
            name="form-add-company"
            layout={"vertical"}
            onFinish={onFinish}
          >
            {allCategories && (
              <>
                <Form.Item name="category" label="Category">
                  <Select
                    showSearch
                    onChange={handleChange}
                    optionFilterProp="children"
                    placeholder="Select category"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allCategories.map((category) => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="rating"
                  allowClear
                  autoClearSearchValue
                  label="Category Ratings"
                  rules={[
                    {
                      required: true,
                      message: "Please enter at least one category rating",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    mode="multiple"
                    optionFilterProp="children"
                    allowClear
                    placeholder="Enter category ratings"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {ratings &&
                      ratings.length > 0 &&
                      ratings.map((rating) => (
                        <Select.Option
                          key={rating.ratingId}
                          value={rating.ratingId}
                        >
                          {rating.rating}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </>
            )}

            <div className="submit-row">
              <Form.Item>
                <Button type="primary" htmlType="submit" className="btn--save">
                  Save
                </Button>
              </Form.Item>
              <Button
                onClick={onCloseModal}
                htmlType="button"
                className="btn--cancel"
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </ModalWrapper>

      <ModalWrapper open={openEdit} onCloseModal={onCloseEdit}>
        <div className="company-form-edit">
          <h3>Edit Company Information</h3>

          <h6>Choose Photo</h6>

          {Object.keys(company).length !== 0 && company.constructor !== {} && (
            <Form
              initialValues={{
                company_name_edit: company.name,
                amazon_url_edit: company.amazonUrl,
                headquarters_edit: company.headquarter,
                description_edit: company.description,
              }}
              name="form-add-company"
              layout={"vertical"}
              onFinish={onFinishEdit}
            >
              <ImgCrop rotate>
                <Upload
                  className="dragger__container"
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                >
                  {fileList.length === 0 && (
                    <div>
                      <img
                        src={ImgUpload}
                        className="upload"
                        alt="img-upload"
                      />
                      <p>Drag your image here </p>
                    </div>
                  )}
                </Upload>
              </ImgCrop>

              {!hideSection && (
                <ImgCrop rotate>
                  <Upload
                    className="dragger__button"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                    onDownload={hideSection}
                  >
                    {fileList.length === 0 && (
                      <Button className="btn-upload" htmlType="button">
                        Upload Image
                      </Button>
                    )}
                  </Upload>
                </ImgCrop>
              )}

              {hideSection && (
                <div className="btns-row">
                  <Button className="btn-upload" htmlType="button">
                    Upload Image
                  </Button>
                  <button className="btn-remove" onClick={remove}>
                    Cancel
                  </button>
                </div>
              )}

              <Form.Item
                name="company_name_edit"
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter Company Name",
                  },
                ]}
              >
                <Input placeholder="Enter company name" />
              </Form.Item>

              <Form.Item
                name="amazon_url_edit"
                label="Amazon URL"
                rules={[
                  {
                    required: true,
                    message: "Please enter Enter amazon url",
                  },
                ]}
              >
                <Input placeholder="Enter amazon url" />
              </Form.Item>
              <Form.Item
                name="headquarters_edit"
                label="Headquarters"
                rules={[
                  {
                    required: true,
                    message: "Please enter headquarters location",
                  },
                ]}
              >
                <Input placeholder="Enter headquarters location" />
              </Form.Item>

              <Form.Item
                name="description_edit"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: "Please enter company description",
                  },
                ]}
              >
                <Input.TextArea placeholder="Enter company description..." />
              </Form.Item>

              <div className="submit-row">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn--save"
                  >
                    Save
                  </Button>
                </Form.Item>
                <Button
                  htmlType="button"
                  onClick={onCloseEdit}
                  className="btn--cancel"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </div>
      </ModalWrapper>
      <GlobalRatingForm
        open={openEditGlobalRating}
        onCloseModal={onCloseEditGlobalRating}
        onFinish={onFinishEditGlobalRatings}
      />

      {Object.keys(company).length !== 0 && company.constructor !== {} && (
        <CategoryRatingForm
          ratingId={categoryId}
          ratings={ratings}
          initialValues={{
            category: [
              company.categories[0]?.name === "Beauty"
                ? "Beauty/Skincare"
                : company.categories[0]?.name === "Food"
                ? "Food/Beverage"
                : company.categories[0]?.name,
              company.categories[0]?.id,
            ],
          }}
          categories={company?.categories}
          allCategories={allCategories}
          openModal={openEditCategoryRating}
          handleChangeCategory={handleChangeCategory}
          onCloseModal={onCloseEditCategoryRating}
          categorySelected={categorySelected}
          onFinish={onFinishEditCategoryRating}
        />
      )}
    </div>
  );
}
