import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import TableOrders from "../components/Table";
import Block from "../components/Block";
import Title from "../components/Title";
import localAuth from "../util/local-auth";
import { formatCurrency } from "util/helpers";
import { DownOutlined, ShoppingOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Button } from "antd";
import { useRouter } from "../util/router";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    render: (id) => `${id}`,
  },
  {
    title: "Order Date",
    dataIndex: "created_at",
    render: (created_at) => `${created_at}`,
  },
  {
    title: "Store",
    dataIndex: "store",
    render: (store) => `${store}`,
  },
  {
    title: "Customer",
    dataIndex: "customer",
    render: (customer) => `${customer}`,
  },
  {
    title: "Order Total",
    dataIndex: "order_total",
    render: (order_total) => formatCurrency(order_total, 1),
  },
  {
    title: "Merchant Order Id",
    dataIndex: "merchant_order_id",
    render: (merchant_order_id) => `${merchant_order_id}`,
  },
  {
    title: "Elegible Donation Amount",
    dataIndex: "elegible_donation_amount",
    render: (elegible_donation_amount) =>
      formatCurrency(elegible_donation_amount, 1),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status) => `${status}`,
  },
];

function Orders() {
  const router = useRouter();
  const [allOrders, setOrders] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalOrders, setTotalOrders] = useState("");
  const [pagination, setPagination] = useState({});
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const brand_id = localStorage.getItem("brand_id");

  const showOrderDetails = (row) => {
    router.push(`/store/${row.id}`);
  };

  const getOrders = useCallback(async (currentPage, pageSize, brandId) => {
    try {
      setLoading(true);

      const response = await localAuth.getOrdersDonations(
        currentPage ? currentPage : 1,
        pageSize ? pageSize : defaultPageSize,
        brandId ? brandId : 0
      );

      if (response.success) {
        setPagination({
          current: response.data.current_page,
          pageSize: pageSize ? pageSize : defaultPageSize,
          total: response.data.total_items,
          onChange: function (page, pageSize) {
            setDefaultPageSize(pageSize);
            getOrders(page, pageSize, 0);
          },
        });

        setTotalOrders(response.data.total_items);
        setOrders(response.data.rows);

        setLoading(false);
      }
    } catch (error) {
      console.log({ error });
    }
  }, []);

  const getBasicBrands = useCallback(async () => {
    try {
      setLoading(true);

      const response = await localAuth.getBrandsBasic();

      if (response.success) {
        setBrands(response.data);

        setLoading(false);
      }
    } catch (error) {
      console.log({ error });
    }
  }, []);

  const handleMenuClick = (e) => {
    if (String(e.key) === "0") {
      setSelectedBrand(null);
      getOrders(false, false, 0);
    } else {
      setSelectedBrand(String(e.key));
      getOrders(false, false, e.key);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getOrders();
      getBasicBrands();
    }
    return () => {
      isMounted = false;
    };
  }, [getOrders, getBasicBrands]);

  const ordersTitle = `${
    totalOrders === 1 ? "1 Order" : totalOrders + " Orders"
  }`;

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={"0"} icon={<ShoppingOutlined />}>
        All Brands
      </Menu.Item>
      {brands.map(({ id, name }) => (
        <Menu.Item key={id} icon={<ShoppingOutlined />}>
          {name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Header>
        <></>
      </Header>
      <section className="container">
        <Block appearance="row">
          <Title text={ordersTitle} />
          {brand_id == 0 ? (
            <Dropdown overlay={menu}>
              <Button>
                {selectedBrand
                  ? brands.find((brand) => String(brand.id) === selectedBrand)
                      ?.name
                  : "All Brands"}
                <DownOutlined />
              </Button>
            </Dropdown>
          ) : null}
        </Block>
        <TableOrders
          className={"data__table"}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={allOrders}
          pagination={pagination}
          loading={loading}
          onRow={(row, rowIndex) => {
            return {
              onClick: (event) => {
                showOrderDetails(row);
              }, // click row
            };
          }}
        />
      </section>
    </>
  );
}

export default Orders;
