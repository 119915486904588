import React, { useState } from "react";
import { Modal, Button, Form, Input, Row, Col, List } from "antd";

function StoreInformation({
  store: { name, base_url, platform, platform_store_id },
}) {
  const data = [
    { key: "Store Name", value: name },
    { key: "URL", value: base_url },
    { key: "Platform", value: platform },
    { key: "Platform Store Id", value: platform_store_id },
  ];

  return (
    <>
      <List
        size="small"
        bordered
        dataSource={data}
        renderItem={({ key, value }) => (
          <List.Item>
            <strong>{key}</strong>
            <span>{value}</span>
          </List.Item>
        )}
      />
    </>
  );
}

export default function FormAddStoreToBrand({
  isModalVisible,
  handleCancel,
  onFinish,
  form
}) {
  const [storeData, setStoreData] = useState(false);

  const searchStoreById = async ({ storeId }) => {
    const url =
      process.env.REACT_APP_INSPIRED_URL + `/api/v1/stores/${storeId}`;
    const headers = {
      apikey: process.env.REACT_APP_API_KEY,
    };
    const response = await fetch(url, { method: "GET", headers });

    const { data } = await response.json();
    setStoreData(data);
  };

  return (
    <Modal
      title="Add Store to Brand"
      centered
      visible={isModalVisible}
      wrapClassName="FormModal"
    >
      <Form
        form={form}
        name={"search-store"}
        layout={"vertical"}
        onFinish={searchStoreById}
        initialValues={{}}
      >
        <Row>
          <Col span={19}>
            <Form.Item
              label="Enter Store ID"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="storeId"
              rules={[
                {
                  required: true,
                  message: "Please enter Shopify store ID",
                },
              ]}
            >
              <Input placeholder="Store ID" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <div style={{ paddingTop: "34px" }}>
              <Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    className="btn--search"
                  >
                    Search
                  </Button>
                </Form.Item>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <div>{storeData && <StoreInformation store={storeData} />}</div>
      </Form>
      <Form
        name={"form-add-store-to-brand"}
        layout={"vertical"}
        onFinish={() => onFinish({ platform_store_id: storeData.platform_store_id })}
      >
        <div className="submit-row">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="btn--save"
              disabled={!storeData}
            >
              Add Store to Brand
            </Button>
          </Form.Item>
          <Button
            onClick={handleCancel}
            htmlType="button"
            className="btn--cancel"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
