import React from "react";
import { Button } from "antd";
import IconAdd from "../../../assets/images/Vector.svg";
const BasicButton = ({ name, appearance = "basic", icon = false , onClick}) => (
  <Button className={`button button--${appearance}`} onClick={onClick}>
    {icon && <img src={IconAdd} alt="Icon" />} {name}
  </Button>
);

export default BasicButton;
