import React from "react";
import ModalWrapper from "../../ModalWrapper";

import { Form, Input, Checkbox, Button, Select} from "antd";

import Arrow from "../../../assets/images/Shape.svg";
const { Option } = Select;


function CategoryRatingForm({
  openModal,
  onCloseModal,
  allCategories,
  handleChangeCategory,
  ratings,
  categorySelected,
  onFinish, 
  initialValues
}) {

  return (
    <ModalWrapper open={openModal} onCloseModal={onCloseModal}>
      <div className="form-edit-category-rating">
        <h4>Edit Category Rating</h4>

       

       

        <Form layout="vertical" name="form-edit-category-rating" onFinish={onFinish} initialValues={initialValues}>
         
        <Form.Item name="category"  rules={[{ required: true }]}>
        <Select
          placeholder="Select a option and change input text above"
          onChange={handleChangeCategory}
          suffixIcon={<img src={Arrow} alt="icon"/>}
        >

{allCategories &&
              allCategories.map((category) => (
                <Option key={category.name} value={[ category.name , category.id]} style={{ fontFamily:"Inter"}}>
                  {category.name === "Beauty"
                    ? "Beauty/Skincare"
                    : category.name === "Food"
                    ? "Food/Beverage"
                    : category.name}
                </Option>
              ))}
        
        </Select>
      </Form.Item>
         
         
         
         
         
          {categorySelected && (
            <Form.Item name="checkbox-group" label="Select All That Apply">
              <Checkbox.Group>
                {ratings &&
                  ratings.length > 0 &&
                  ratings.map((rating) => (
                    <Checkbox
                      key={rating.ratingId}
                      value={rating.ratingId}
                      style={{ lineHeight: "32px" }}
                    >
                      {rating.rating}
                    </Checkbox>
                  ))}
              </Checkbox.Group>
            </Form.Item>
          )}

          {categorySelected &&
            (categorySelected === "beauty" || categorySelected === "food") && (
              <>


                <Form.Item
                  name="additional_certification"
                  label="Other Certification"
                >
                  <Input placeholder="Enter Certification" />
                </Form.Item>

                <Form.Item name="additional_certification_two">
                  <Input placeholder="Enter Certification" />
                </Form.Item>
              </>
            )}
          {categorySelected && categorySelected === "medication" && (
            <>
              <Form.Item name="generic_name" label="Generic Name">
                <Input placeholder="Enter Generic Name" />
              </Form.Item>

              <Form.Item name="drug_also_know_as" label="Drug also known as">
                <Input placeholder="Enter other known names of medication" />
              </Form.Item>
              <Form.Item name="drug_class" label="Drug Class">
                <Input placeholder="Enter drug class" />
              </Form.Item>

              <Form.Item name="dosage_forms" label="Dosage Forms">
                <Input placeholder="Enter dosage forms" />
              </Form.Item>
              <Form.Item name="overview" label="Overview">
                <Input.TextArea placeholder="Enter an overview description" />
              </Form.Item>
              <Form.Item name="side_effects" label="Side Effects">
                <Input.TextArea placeholder="Enter side effects" />
              </Form.Item>
            </>
          )}
          <div className="submit-row">
            <Form.Item>
              <Button type="primary" htmlType="submit" className="btn--save">
                Save
              </Button>
            </Form.Item>
            <Button htmlType="button" onClick={onCloseModal} className="btn--cancel">
              Cancel
            </Button>
          </div>
        </Form>
       
      </div>
    </ModalWrapper>
  );
}

export default CategoryRatingForm;
