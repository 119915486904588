import Img1 from "../assets/images/glossier.png";

export const glossierDetail = {
  companyImage: Img1,
  name: "Glossier",
  category: "Beauty",
  amazonUrl: "https://www.amazon.com/s?k=Glossier&ref=bl_dp_s_web_10525420011",
  headquarters: "Los Angeles, CA",
  manufacturingCountries: "United States",
  content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  globalRatings: [
    {name:"B-Corp Certified"},
    {name:"EWG Certified"},
    {name:"BBB Accredited + Score"},
    {name:"Glassdor Rating"},
    {name:"ESG Score"},
  ],
  categoryRatings: [
    {name:"Leaping Bunny Certified"},
    {name:"Paraben-free"},
    {name:"Organic"},
    {name:"Cruelty Free"},
    {name:"Vegan Products Available"},
  ],
};
