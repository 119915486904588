import React, { useState, useEffect, useCallback } from "react";
import Header from "../components/Layout/Header";
import Title from "../components/Title";
import Block from "../components/Block";
import TableCampaigns from "../components/Table";
import localAuth from "../util/local-auth";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    render: (id) => `${id}`,
  },
  {
    title: "Campaign Name",
    dataIndex: "name",
    render: (name) => `${name}`,
  },
  {
    title: "Donation Type",
    dataIndex: "type",
    render: (type) => `${type}`,
  },
  {
    title: "Donation Value",
    dataIndex: "type",
    render: (type, row) => `${type == 'fixed' ? (row.fixed + 'USD') : (row.percent + '%')}`,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status) => `${status}`,
  }
];

function CampaignsPage() {
  const [campaigns, setCampaigns] = useState(false);

  const getCampaigns = useCallback(async () => {
    let response = [];
    try {
      response = await localAuth.getCampaigns();
      console.log(response);
      if (response.success) {
        setCampaigns(response.data.rows);
      }
    } catch (error) {
      console.log({ error });
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCampaigns();
    }
    return () => {
      isMounted = false;
    };
  }, [getCampaigns]);

  return (
    <>
      <Header>
        <></>
      </Header>
      {campaigns && campaigns.length > 0 && (
        <section className="container">
          <Block appearance="row">
            <Title text="Campaigns" />
          </Block>

          <TableCampaigns
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={campaigns}
            loading={false}
          />
        </section>
      )}

    </>
  );
}

export default CampaignsPage;
