import { apiRequest, inspiredApiRequest } from "./util.js";

const requests = {
  login: (data) => {
    return inspiredApiRequest("/users/login", "POST", data);
  },
  getStores: () => {
    return inspiredApiRequest("/stores", "GET");
  },
  getCauseCategories: () => {
    return inspiredApiRequest("/categories", "GET");
  },
  getCampaigns: () => {
    return inspiredApiRequest("/campaigns?size=20", "GET");
  },
  getCampaignsByStore: (storeId) => {
    return inspiredApiRequest("/stores/" + storeId + "/campaigns", "GET");
  },
  getCausesByStore: (storeId) => {
    return inspiredApiRequest("/stores/" + storeId + "/causes", "GET");
  },
  getStore: (id) => {
    return inspiredApiRequest("/stores/" + id, "GET");
  },
  getOrders: () => {
    return inspiredApiRequest("/orders/search", "GET");
  },
  updateStore: (storeId, data) => {
    return inspiredApiRequest("/stores/" + storeId, "PUT", data);
  },
  changeStoreLogo: (id, data) => {
    return inspiredApiRequest("/stores/" + id + "/logo", "POST", data);
  },
  createCauseCollection: (data) => {
    return inspiredApiRequest("/causes", "POST", data);
  },
  searchCharities: (topic, currentPage, pageSize) => {
    return inspiredApiRequest(
      "/charities/search?topic=" +
        topic +
        "&size=" +
        pageSize +
        "&page=" +
        currentPage,
      "GET"
    );
  },
  getCharities: (currentPage, pageSize) => {
    return inspiredApiRequest(
      "/charities?size=" + pageSize + "&page=" + currentPage,
      "GET"
    );
  },
  changeBrandLogo: (id, data) => {
    return inspiredApiRequest("/brands/" + id + "/logo", "POST", data);
  },
  getBrands: () => {
    return inspiredApiRequest("/brands", "GET");
  },
  getBrandsBasic: () => {
    return inspiredApiRequest("/brands?basic=true", "GET");
  },
  createBrand: (data) => {
    return inspiredApiRequest("/brands", "POST", data);
  },
  getBrand: (id) => {
    return inspiredApiRequest("/brands/" + id, "GET");
  },
  getCauseCollectionsDonations: (currentPage, pageSize) => {
    return inspiredApiRequest(
      "/donations/collections?size=" + pageSize + "&page=" + currentPage,
      "GET"
    );
  },
  getCauseCollectionByBrand: (id, currentPage, pageSize, topic) => {
    return inspiredApiRequest(
      "/brands/" +
        id +
        "/causes?size=" +
        pageSize +
        "&page=" +
        currentPage +
        "&topic=" +
        topic,
      "GET"
    );
  },
  getCharitiesDonations: () => {
    return inspiredApiRequest("/donations/charities", "GET");
  },
  getCharitiesDonationsById: (id) => {
    return inspiredApiRequest(`/donations/charities/${id}`, "GET");
  },
  getCharityWithDonations: (id) => {
    return inspiredApiRequest(`/charities/${id}/donations`, "GET");
  },
  getBrandsDonations: (currentPage, pageSize) => {
    return inspiredApiRequest(
      "/donations/brands" + "?size=" + pageSize + "&page=" + currentPage,
      "GET"
    );
  },
  getBrandDonations: (id, currentPage, pageSize) => {
    return inspiredApiRequest(
      "/donations/brands/" + id + "?size=" + pageSize + "&page=" + currentPage,
      "GET"
    );
  },
  getBrandTransactions: (id, currentPage, pageSize) => {
    return inspiredApiRequest(
      "/transactions/brands/" +
        id +
        "?size=" +
        pageSize +
        "&page=" +
        currentPage,
      "GET"
    );
  },
  getStoresDonations: (currentPage, pageSize, storeName) => {
    return inspiredApiRequest(
      "/donations/stores?size=" + pageSize + "&page=" + currentPage + "&store_name=" + storeName,
      "GET"
    );
  },
  getStoreDonations: (id, currentPage, pageSize) => {
    return inspiredApiRequest(
      "/donations/stores/" + id + "?size=" + pageSize + "&page=" + currentPage,
      "GET"
    );
  },
  getStoreTransactions: (id, currentPage, pageSize) => {
    return inspiredApiRequest(
      "/transactions/stores/" +
        id +
        "?size=" +
        pageSize +
        "&page=" +
        currentPage,
      "GET"
    );
  },
  linkStore: (storeId, data) => {
    return inspiredApiRequest("/stores/" + storeId + "/link", "POST", data);
  },
  getCharitiesTransactionsById: (id) => {
    return inspiredApiRequest(`/transactions/charities/${id}`, "GET");
  },
  getCauseCollectionDonations: (id) => {
    return inspiredApiRequest("/donations/collections/" + id, "GET");
  },
  getCauseCollectionTransactions: (id) => {
    return inspiredApiRequest("/transactions/collections/" + id, "GET");
  },
  getCustomers: (currentPage, pageSize) => {
    return inspiredApiRequest(
      "/customers?size=" + pageSize + "&page=" + currentPage,
      "GET"
    );
  },
  saveBrandStylingSettings: (brandId, data) => {
    return inspiredApiRequest("/brands/" + brandId, "PUT", data);
  },
  saveBrandGlobalCauses: (brandId, data) => {
    return inspiredApiRequest("/brands/" + brandId, "PUT", data);
  },
  getCustomer: (customerId) => {
    return inspiredApiRequest("/customers/" + customerId, "GET");
  },
  getCustomerTransactions: (customerId, currentPage, pageSize) => {
    return inspiredApiRequest(
      `/transactions/customers/` +
        customerId +
        "?size=" +
        pageSize +
        "&page=" +
        currentPage,
      "GET"
    );
  },
  getCustomerOrders: (customerId, currentPage, pageSize) => {
    return inspiredApiRequest(
      `/orders/customers/` +
        customerId +
        "?size=" +
        pageSize +
        "&page=" +
        currentPage,
      "GET"
    );
  },  
  getCauseCollection: (id) => {
    return inspiredApiRequest("/causes/" + id, "GET");
  },
  getOrdersDonations: (currentPage, pageSize, brandId) => {
    return inspiredApiRequest(
      "/donations/orders?size=" +
        pageSize +
        "&page=" +
        currentPage +
        "&brand_id=" +
        brandId,
      "GET"
    );
  },
  getOrderDonations: (orderId) => {
    return inspiredApiRequest(
      "/donations/orders/" + orderId,
      "GET"
    );
  },  
  getMetrics: () => inspiredApiRequest('/dashboard/platform/metrics', 'GET'),
  getDonations: () => inspiredApiRequest('/dashboard/platform/donations', 'GET'),
  getUsers: (currentPage, pageSize) => inspiredApiRequest("/users?size=" + pageSize + "&page=" + currentPage, 'GET'),
  deleteUser: userId => inspiredApiRequest("/users/" + userId, 'DELETE'),
  updateUser: data => inspiredApiRequest("/users", "PUT", data),
  createUser: (data) => {
    return inspiredApiRequest("/users", "POST", data);
  },  
};
export default requests;
