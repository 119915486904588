import React from "react";
import Header from "../components/Layout/Header";
import Title from "../components/Title";
import Block from "../components/Block";

export default function Payments() {
  return (
    <>
      <Header>
        <></>
      </Header>
      <section className="container">
        <Block appearance="row">
          <Title text="Payments" />
        </Block>
      </section>
    </>
  );
}
