import React from "react";
import EditButton from "../Buttons/EditButton";
import moment from "moment";
import { Tooltip } from "antd";
import {
  EditOutlined 
} from '@ant-design/icons';

export default function index({ storeInfo, openEdit, img64,handleUploadModal  }) {
  return (
    <div className="store">
      <div className="storeDetail">
      <Tooltip placement="bottom" title={"Edit image"}>
        <div className="img__container">
          <div className="img">
            {storeInfo.img_url && (
              <img
                src={img64 !== "" ? img64 : storeInfo.img_url}
                alt={storeInfo.name}
              />
            )}
             <div className="container inputfile">
          <label>
          <EditOutlined /> 
            <input
              type="file"
              multiple
              onChange={handleUploadModal}
              accept="image/x-png,image/jpeg"
            />
          </label>
        </div>

          </div>
        </div>
        </Tooltip>
        <div className="text__container">
          <div className="title__container">
            <h2>{storeInfo.name}</h2>
            <EditButton event={openEdit} />
          </div>
          <div className="detail">
            <p>
              Base Url{" "}
              <span>
                <a href={storeInfo.base_url} target="_blank" rel="noreferrer">
                  {storeInfo?.base_url}
                </a>
              </span>
            </p>
            <p>
              Platform
              <span>{storeInfo?.platform}</span>
            </p>
            <p>
              Platform Store Id
              <span>{storeInfo?.platform_store_id}</span>
            </p>
            <p>
              Created At{" "}
              <span>
                {moment(storeInfo?.createdAt).format("MM/DD/YYYY h:mma") +
                  " PST"}
              </span>
            </p>
            <p>
              Updated At{" "}
              <span>
                {moment(storeInfo?.updatedAt).format("MM/DD/YYYY h:mma") +
                  " PST"}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
