import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input, Tabs } from "antd";

import CollectionsTable from "components/Table";
import IndividualCausesTable from "components/Table";
const { TabPane } = Tabs;

export default function index({
  form,
  toggleEditCauses,
  causeCollectionsData,
  causeCollectionsColumns,
  causeCollectionsSelected,
  removeFeaturedCauses,
  loading,
  columns,
  individualCausesPagination,
  handleSearchCharities,
  handleSearchCollections,
  individualCauses,
  individualCausesSelected,
  onFinish,
  resetFeaturedCauses,
  causeCollectionsPagination,
  maximum,
}) {
  return (
    <Form
      form={form}
      name={"causes-editor"}
      layout={"vertical"}
      onFinish={onFinish}
    >
      <section>
        <div className="selected__causes">
          <h2>Selected Causes</h2>
          {causeCollectionsSelected &&
            causeCollectionsSelected.length > 0 &&
            causeCollectionsSelected.map((cause) => {
              let charities = cause.charities
                ? cause.charities.slice(0, 5)
                : [];

              return (
                <div key={cause.id} className={"cause-selected"}>
                  <img src={cause.logo_url} alt={cause.title} />
                  <div>
                    <h3>{cause.title}</h3>
                    <>
                      {charities &&
                        charities.length > 0 &&
                        charities.map((e) => (
                          <span key={cause.id + "-" + e.id}>
                            {e.organization_name}
                            {e === charities[charities.length - 1] ? "" : ", "}
                          </span>
                        ))}
                      {charities.length > 4 && "..."}
                    </>
                  </div>
                  <Button
                    onClick={() => {
                      /*setCauseCollections([cause, ...causeCollectionsData]);*/
                      removeFeaturedCauses(cause.id);
                    }}
                  >
                    Remove
                  </Button>
                </div>
              );
            })}
          {individualCausesSelected &&
            individualCausesSelected.length > 0 &&
            individualCausesSelected.map((cause) => {
              return (
                <div key={cause.id} className={"cause-selected"}>
                  <img src={cause.logo_url} alt={cause.title} />
                  <div>
                    <h3>{cause.title}</h3>
                  </div>
                  <Button
                    onClick={() => {
                     /* setIndividualCauses([cause, ...individualCauses]);*/
                      removeFeaturedCauses(
                        cause.key ? cause.key : cause.id,
                        cause.key ? cause.key : cause.id
                      );
                    }}
                  >
                    Remove
                  </Button>
                </div>
              );
            })}
        </div>
        <div className="search__row">
          {maximum && <h3>You can choose up to 3 causes</h3>}
          <h3> Causes Search</h3>
          <p>
            {" "}
            Search for Causes & Collections to Add as default Featured Causes.
          </p>
        </div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Individual Causes" key="1">
            <Form.Item name="causes_search">
              <Input
                onChange={handleSearchCharities}
                placeholder="Search something"
                prefix={<SearchOutlined />}
                disabled={maximum}
              />
            </Form.Item>
            <IndividualCausesTable
              rowKey={(record) => record.key}
              loading={loading}
              columns={columns}
              className="collections"
              dataSource={individualCauses}
              pagination={individualCausesPagination}
              scroll={400}
              noTags
            />
          </TabPane>
          <TabPane tab="Inspired Collections" key="2">
            <Form.Item name="collections_search">
              <Input
                onChange={handleSearchCollections}
                placeholder="Search something"
                prefix={<SearchOutlined />}
                disabled={maximum}
              />
            </Form.Item>
            <CollectionsTable
              rowKey={(record) => record.id}
              name="name"
              columns={causeCollectionsColumns}
              dataSource={causeCollectionsData}
              className="collections"
              pagination={causeCollectionsPagination}
              scroll={400}
            />
          </TabPane>
        </Tabs>
      </section>

      <div className="submit-row">
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="btn--save btn--save-purple"
          >
            Save & Close
          </Button>
        </Form.Item>
        <Button
          onClick={() => {
            toggleEditCauses();
            resetFeaturedCauses();
          }}
          htmlType="button"
          className="btn--cancel"
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
}
