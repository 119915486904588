import React from "react";
import { Layout } from "antd";
import SideMenu from "./SideMenu";
const { Content } = Layout;

const PublicRoute = ({ children }) => {
  return (
    <Layout>
      <SideMenu />
      <Layout>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default PublicRoute;
