import React from "react";
import BuyableLogo from "../../assets/images/Logo.svg";
import FormLogin from "../../components/Forms/FormLogin";



export default function SectionLogin({ onFinish, authFailed }) {


  return (
    <section className="login">
      <div className="login__container">
        <img src={BuyableLogo} alt="Buyable" className="login__logo" />

        <FormLogin onFinish={onFinish} authFailed={authFailed}/>
      </div>
    </section>
  );
}
