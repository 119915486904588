import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import TableBrands from "../components/Table";
import Block from "../components/Block";
import Title from "../components/Title";
import localAuth from "../util/local-auth";
import { Form } from "antd";
import FormAddBrand from "../components/Forms/FormAddBrand";
import { useRouter } from "../util/router";
import ButtonAdd from "../components/Buttons/BasicButton";
import swal from "sweetalert";
import { formatCurrency } from "util/helpers";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    render: (name) => `${name}`,
  },
  {
    title: "Email",
    dataIndex: "email",
    render: (email) => `${email}`,
  },
  {
    title: "Contact Name",
    dataIndex: "contact_name",
    render: (contact_name) => `${contact_name}`,
  },
  {
    title: "Transactions Donations (This Period)",
    dataIndex: "period_donations",
    align: "center",
    render: (period_donations) => formatCurrency(period_donations, 1),
  },
  {
    title: "Stores",
    dataIndex: "num_stores",
    render: (num_stores) => `${num_stores != null ? num_stores : 0}`,
  },
  {
    title: "Campaigns (Active)",
    dataIndex: "num_campaigns",
    render: (num_campaigns) => `${num_campaigns != null ? num_campaigns : 0}`,
  },
];

function Brands() {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({});
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [totalBrands, setTotalBrands] = useState("");
  const router = useRouter();

  const showBrandDetails = (row) => {
    console.log(row);
    router.push(`/brand/${row.id}`);
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const getBrands = useCallback(async (currentPage, pageSize) => {
    try {
      setLoading(true);

      const response = await localAuth.getBrandsDonations(
        currentPage ? currentPage : 1,
        pageSize ? pageSize : defaultPageSize
      );

      if (response.success) {
        setPagination({
          current: response.data.current_page,
          pageSize: pageSize ? pageSize : defaultPageSize,
          total: response.data.total_items,
          onChange: function (page, pageSize) {
            setDefaultPageSize(pageSize);
            getBrands(page, pageSize);
          },
        });

        setTotalBrands(response.data.total_items);
        setBrands(response.data.rows);
        setLoading(false);
      }
    } catch (error) {
      console.log({ error });
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getBrands();
    }
    return () => {
      isMounted = false;
    };
  }, [getBrands]);

  const onFinishEdit = async (values) => {
    console.log({ values });
    const data = {
      name: values.brand_name,
      email: values.brand_email,
      contact_name: values.brand_contact_name,
    };

    try {
      const response = await localAuth.createBrand(data);

      if (response.success) {
        swal("The brand was created!", {
          icon: "success",
          buttons: false,
        });
        setOpenModal(false);
        getBrands();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const title = `${totalBrands > 1 ? totalBrands + " " : ""}Brands`;

  return (
    <>
      <Header>
        <></>
      </Header>
      <section className="container">
        <Block appearance="row">
          <Title text={title} />
          <ButtonAdd
            name="Add Brand"
            appearance="add"
            icon
            onClick={toggleModal}
          />
        </Block>
        <TableBrands
          className={"data__table"}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={brands}
          pagination={pagination}
          loading={loading}
          onRow={(row, rowIndex) => {
            return {
              onClick: (event) => {
                showBrandDetails(row);
              }, // click row
            };
          }}
        />
      </section>

      <FormAddBrand
        isModalVisible={openModal}
        onFinishEdit={onFinishEdit}
        handleCancel={toggleModal}
        form={form}
        initialValues={{}}
      />
    </>
  );
}

export default Brands;
