import React from "react";
import moment from "moment";
import { Tooltip } from "antd";
import {
  EditOutlined 
} from '@ant-design/icons';
import { Button } from "antd";
import { formatCurrency } from "util/helpers";

export default function index({
  name,
  email,
  contact_name,
  createdAt,
  updatedAt,
  total_donations,
  total_transactions,
  billing_details,
  onChange, 
  handleOpenCampaignSettings,
  handleOpenStylingSettings,
  img,
  img64
}) {
  return (
    <div className="brand-detail-card">
      <Tooltip placement="bottom" title={"Edit image"}>
      <div className="img__container">
        <img
          alt="brand"
          src={`${img64 ? img64 :img}`}
        />

<div className="input-file__container">
          <label>
          <EditOutlined /> 
            <input
              type="file"
              multiple
              onChange={onChange}
              accept="image/x-png,image/jpeg"
            /> 
          </label>
        </div>
      </div>
      </Tooltip>

      <div>
        
        <h2>{name}</h2>
        <p>
          Email: <span>{email}</span>
        </p>
        <p>
          Contact Name: <span>{contact_name}</span>
        </p>
        {/*
        //Ignoring credentials for now
        <p>
          Credentials <span className="credentials">See</span>
        </p>
        */}
        <p>
          Created: <span>{moment(createdAt).format('M/D/YYYY h:mma')+ ' PST'}</span>
        </p>
        <p>
          Last Update: <span>{moment(updatedAt).format('M/D/YYYY h:mma')+ ' PST'}</span>
        </p>
      </div>
      <div>
        <p>
          Total Donations: <span>{formatCurrency(total_donations, 1)}</span>
        </p>
        <p>
          Total Transactions: <span>{total_transactions}</span>
        </p>

        <span className="space" />
        <p>
          Billing Details: <span>Paypal</span>
        </p>
      </div>
      <div className="default-settings__row">
        <Button onClick={handleOpenCampaignSettings}>Global Causes</Button>
        <Button onClick={handleOpenStylingSettings}>Global Styling</Button>
      </div>
    </div>



  );
}
