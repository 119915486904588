import React, { useCallback, useEffect, useState } from "react";
import { Input } from "antd";
const { Search } = Input;

const FilterInput = (props) => {
  const { onSearch, onChange } = props;
  return (
    <>
      <Search
        placeholder="Search by Name"
        enterButton="Search"
        size="large"
        onSearch={onSearch}
        onChange={onChange}
      />
      <br />
    </>
  );
};

export default FilterInput;
