

 const causes = [
    {
      name: "Cause",
      isDefault: false,
      description:"Cause Description"
    },
  
  ];
 export default causes;