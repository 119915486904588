import { useState, useCallback, useEffect } from "react";
import Header from "../components/Layout/Header";

import Title from "components/Title";
import Block from "components/Block";
import Table from "components/Table";

import { useRouter } from "util/router";
import { formatCurrency } from "util/helpers";
import localAuth from "util/local-auth";

// KEEP: Columns for the table
export const columns = [
  {
    title: "Charity Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "EIN",
    dataIndex: "ein",
    key: "ein",
  },
  {
    title: "Location",
    dataIndex: "city",
    key: "city",
  },
  {
    title: "G.S. Level",
    dataIndex: "gslevel",
    key: "gslevel",
    render: () => `Platinum`,
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Donations (This Period)",
    dataIndex: "period_donations",
    key: "period_donations",
    render: (period_donations) => formatCurrency(period_donations, 1),
  },
  {
    title: "Total Donations",
    dataIndex: "total_donations",
    key: "total_donations",
    render: (total_donations) => formatCurrency(total_donations, 1),
  },
  {
    title: "",
    dataIndex: "id",
    align: "center",
    width: "8%"
  },
];

export default function CharitiesPage() {
  const router = useRouter();
  const [charitiesList, setCharitiesList] = useState([]);
  const [charitiesTotal, setCharitiesTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const showCharityDetails = (row) => {
    router.push(`/charities/${row.id}`);
  };

  const getCharities = useCallback(async () => {
    try {
      setLoading(true);

      let response = await localAuth.getCharitiesDonations();

      if (response.success) {
        setLoading(false);
        setCharitiesTotal(response.data.total_items);
        setCharitiesList(response.data.rows);
      }
    } catch (error) {
      console.log({ error });
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCharities();
    }
    return () => {
      isMounted = false;
    };
  }, [getCharities]);

  const title = `${charitiesTotal > 1 ? charitiesTotal + " " : ""}Charities`;

  return (
    <>
      <Header />
      <section className="container">
        <Block appearance="row">
          <Title text={title} />
        </Block>
        <Table
          className={"data__table"}
          columns={columns}
          dataSource={charitiesList}
          loading={loading}
          onRow={(row, rowIndex) => {
            return {
              onClick: (event) => {
                showCharityDetails(row);
              }, // click row
            };
          }}
        />
      </section>
    </>
  );
}
