import { useCallback, useEffect, useState } from "react";

import { Button } from "antd";
import { LeftCircleFilled } from "@ant-design/icons";

import CardCharityDetail from "components/CardCharityDetail";
import Table from "components/Table";

import { useRouter, Link } from "util/router";
import localAuth from "util/local-auth";
import { formatCurrency, formatDate, toTitleCase } from "util/helpers";

export const donationColumns = [
  {
    title: "Store",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Transactions",
    dataIndex: "donations",
    key: "donations",
  },
  {
    title: "Total Donations",
    dataIndex: "transactions",
    key: "transactions",
    render: (money) => formatCurrency(money, 1),
  },
];

export const transactionColumns = [
  {
    title: "Transaction ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (date) => formatDate(date, "onlyDate"),
  },
  {
    title: "Campaign ID",
    dataIndex: "charity_id",
    key: "charity_id",
  },
  {
    title: "Donation",
    dataIndex: "donation",
    key: "donation",
    render: (money) => formatCurrency(money, 1),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (text) => toTitleCase(text),
  },
  {
    title: "Donation Period",
    dataIndex: "date",
    key: "date",
    render: (date) => formatDate(date, "monthYear"),
  },
];

export default function CharityDetailPage() {
  const router = useRouter();
  const charityId = router.query.id;
  const [loading, setLoading] = useState(true);
  const [charity, setCharity] = useState(false);
  const [donations, setDonations] = useState([]);
  const [transactions, setSransactions] = useState([]);

  const getDetailById = useCallback(
    async (charityId) => {
      try {
        setLoading(true);

        const response = await localAuth.getCharityWithDonations(charityId);
        if(response.success){
          
          response.data.period_donations = formatCurrency(response.data.period_donations, 1);
          response.data.total_donations = formatCurrency(response.data.total_donations, 1);

          setCharity(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.log({ error });
      }

      try {
        const response = await localAuth.getCharitiesDonationsById(charityId);

        if (!response.success) return false;
        setDonations(response.data.rows);
      } catch (error) {
        console.log({ error });
      }

      try {
        const response = await localAuth.getCharitiesTransactionsById(charityId);

        if (!response.success) return false;
        setSransactions(response.data.rows);
      } catch (error) {
        console.log({ error });
      }
    },
    [router]
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getDetailById(charityId);
    }
    return () => {
      isMounted = false;
    };
  }, [getDetailById, charityId]);

  return (
    <section className="container">
      <div style={{ paddingBottom: "32px" }}>
        <Link to={`/charities/`}>
          <Button shape="round" icon={<LeftCircleFilled />} type="text">
            All Charities
          </Button>
        </Link>
      </div>

      <CardCharityDetail {...charity} />

      {!loading && (
        <div style={{ padding: "32px 0 9" }}>
          <div style={{ padding: "32px 8px" }}>
            <h2 style={{ fontWeight: "bold" }}>
              {donations.length} Store{donations.length !== 1 ? "s" : ""}
            </h2>
          </div>
          <Table
            columns={donationColumns}
            dataSource={donations}
            loading={loading}
          />
        </div>
      )}

      {!loading && (
        <div style={{ padding: "0 0 0" }}>
          <div style={{ padding: "0 8px 16px" }}>
            <h2 style={{ fontWeight: "bold" }}>
              {transactions.length} Transaction
              {transactions.length !== 1 ? "s" : ""}
            </h2>
          </div>
          <Table
            columns={transactionColumns}
            dataSource={transactions}
            loading={loading}
          />
        </div>
      )}
    </section>
  );
}
