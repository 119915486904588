import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Card } from 'antd';
import Header from "../components/Layout/Header";
import TableOrders from "../components/Table";
import Block from "../components/Block";
import Title from "../components/Title";
import localAuth from "../util/local-auth";
import { formatCurrency } from "util/helpers";
import { useRouter } from "util/router";

const columns = [
    {
        title: "SKU",
        dataIndex: "platform_product_id",
        render: (platform_product_id) => `${platform_product_id}`,
    },
    {
        title: "Product Name",
        dataIndex: "platform_product_name",
        render: (platform_product_name) => `${platform_product_name}`,
    },
    {
        title: "Price",
        dataIndex: "price",
        render: (price) => formatCurrency(price, 1)
    },
    {
      title: "Quantity",
      dataIndex: "final_quantity",
      render: (final_quantity) => final_quantity
    },
    {
      title: "Amount",
      dataIndex: "subtotal_amount",
      render: (subtotal_amount) => formatCurrency(subtotal_amount, 1)
  },    
    {
        title: "Elegible Donation Amount",
        dataIndex: "donation_amount",
        render: (donation_amount, row) => row.status == "Elegible" ? formatCurrency(donation_amount, 1) : ""
    },
    {
        title: "Status",
        dataIndex: "status",
        render: (status) => `${status}`
    }    
];

function OrderDetail() {
    const router = useRouter();
    const orderId = router.query.id;  
    const [products, setProducts] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalProducts, setTotalProducts] = useState("");
    const [totalOrderAmount, setTotalOrderAmount] = useState("");
    const [elegibleDonationAmount, setElegibleDonationAmount] = useState("");
    const [elegibleDonations, setElegibleDonations] = useState("");
    const [percentageElegible, setPercentageElegible] = useState("");
    const [donationAmount, setDonationAmount] = useState("");
    const [campaign, setCampaign] = useState({
      id: "", name: "", type: "", max_donation: ""
    });
    const [order, setOrder] = useState({
      status: "", platform_order_id : "", customer: ""
    });

    const getOrderById = useCallback(async () => {
        try {
            setLoading(true);

            const response = await localAuth.getOrderDonations(orderId);

            if (response.success && response.data) {
              const order = response.data.order;
              order.customer = `${order.Customer.first_name} ${order.Customer.last_name}`
              setOrder(order);
              setDonationAmount(formatCurrency(order.elegible_donation_amount, 1));

              const campaign = response.data.campaign;
              campaign.type = campaign.type == "percentage" ? `${campaign.percent}%` : `${formatCurrency(campaign.fixed, 1)} fixed`;
              campaign.max_donation = formatCurrency(campaign.max_donation, 1);
              setCampaign(campaign);

              const donations = response.data.donations;

              const elegibles = donations.filter((d) => d.status === "Elegible");
              const orderAmount = donations.reduce((sum, val) => sum + val.subtotal_amount, 0);

              if(elegibles && elegibles.length > 0){
                const amount = elegibles.reduce((sum, val) => sum + val.donation_amount, 0);
                const percentage = (elegibles.length / donations.length ) * 100;

                setElegibleDonations(elegibles.length);
                setElegibleDonationAmount(formatCurrency(amount, 1));
                setTotalOrderAmount(formatCurrency(orderAmount, 1));
                setPercentageElegible(`${percentage.toFixed(2)}%`);
              }
              
              setProducts(donations);
              setTotalProducts(donations.length);
              setLoading(false);
            }
        } catch (error) {
            console.log({ error });
        }
    }, []);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            getOrderById();
        }
        return () => {
            isMounted = false;
        };
    }, [getOrderById]);

    const totalProductsTitle = `${totalProducts === 1 ? "1 Product" : totalProducts + " Products"}`;

    return (
      <>
        <Header>
          <></>
        </Header>
        <section className="container">
        <Block appearance="row">
            <Title text={`Order ${order.platform_order_id}`} />
          </Block>
          <Block appearance="row">
            <p>
              <strong style={{fontWeight : "bold"}}>Customer:</strong> {order.customer}
            </p>
            <p>
              <strong style={{fontWeight : "bold"}}>Status</strong> {order.status}
            </p>
          </Block>          
          <Block appearance="row">
            <Title text="Donation Details" />
          </Block>
          <Block appearance="row">
            {
              campaign.id === 0 ? <strong style={{fontWeight : "bold"}}>*** Order Not In Campaign ***</strong> :
            <p>
              <strong style={{fontWeight : "bold"}}>Campaign ID</strong> {campaign.id} - {campaign.name}, 
              <strong style={{fontWeight : "bold"}}> Donation Type:</strong> {campaign.type},
              <strong style={{fontWeight : "bold"}}> Max Donation:</strong> {campaign.max_donation}
            </p>
            }
          </Block>
          <Row justify="start">
          <Card title="Total Order Amount" style={{ marginRight: "20px", minWidth: "180px" }}>
              <p style={{ fontWeight: 'bold'}}>
                {totalOrderAmount}
              </p>
            </Card>            
            <Card title="Elegible Donation Amount" style={{ marginRight: "20px", minWidth: "180px" }}>
              <p style={{ fontWeight: 'bold'}}>
                {elegibleDonationAmount}
              </p>
            </Card>
            <Card title="Donation Amount" style={{ marginRight: "20px", minWidth: "180px" }}>
              <p style={{ fontWeight: 'bold'}}>
                {donationAmount}
              </p>
            </Card>            
            <Card title="Elegible Donations" style={{ marginRight: "20px", minWidth: "180px" }}>
              <p style={{ fontWeight: 'bold'}}>
                {elegibleDonations}
              </p>
            </Card>
            <Card title="% Of Elegible" style={{ minWidth: "180px" }}>
              <p style={{ fontWeight: 'bold'}}>
                {percentageElegible}
              </p>
            </Card>            
          </Row>
          <Block appearance="row">
            <Title text={totalProductsTitle} />
          </Block>
          <TableOrders
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={products}
            loading={loading}
          />
        </section>
      </>
    );
}

export default OrderDetail;
