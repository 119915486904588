import React, { useEffect, useCallback, useState } from "react";
import { useRouter } from "../util/router";
import localAuth from "../util/local-auth";
import { Header } from "antd/lib/layout/layout";
import Breadcrumb from "../components/Breadcrumb";
import Store from "../components/Store";
import TableCampaigns from "../components/Table";
import Title from "../components/Title";
import Block from "../components/Block";
import FormEdit from "../components/Forms/FormEdit";
import TableTransactions from "../components/Table";
import ModalUpload from "../components/ModalUpload";
import swal from "sweetalert";
import { formatCurrency } from "util/helpers";

const columnsCampaigns = [
  {
    title: "ID",
    dataIndex: "id",
    render: (id) => `${id}`,
  },
  {
    title: "Campaign Name",
    dataIndex: "campaign_name",
    render: (campaign_name) => `${campaign_name}`,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status) => `${status}`,
  },
  {
    title: "Run Date",
    dataIndex: "start_date",
    render: (start_date, row) => `${start_date} - ${row.end_date}`,
  },
  {
    title: "Donation Type",
    dataIndex: "donation_type",
    render: (donation_type) => `${donation_type}`,
  },
  {
    title: "Donation This Period",
    dataIndex: "period_donations",
    render: (period_donations) =>
      period_donations != null ? formatCurrency(period_donations, 1) : 0,
  },
  {
    title: "Total Donations",
    dataIndex: "total_donations",
    render: (total_donations) => formatCurrency(total_donations, 1),
  },
];
const columnsTransactions = [
  {
    title: "Transaction ID",
    dataIndex: "id",
    render: (id) => `${id}`,
  },
  {
    title: "Date",
    dataIndex: "date",
    render: (date) => `${date}`,
  },
  {
    title: "Campaign ID",
    dataIndex: "campaign_id",
    render: (campaign_id) => `${campaign_id}`,
  },
  {
    title: "Donation",
    dataIndex: "donation",
    render: (donation) => formatCurrency(donation, 1),
  },
  {
    title: "Type",
    dataIndex: "type",
    render: (type) => `${type}`,
  },
  {
    title: "Donation Period",
    dataIndex: "month",
    render: (month, row) => `${month} ${row.year}`,
  },
];

export default function StoreDetail() {
  const router = useRouter();
  const storeId = router.query.id;
  const [storeData, setStoreData] = useState({});

  const [campaigns, setCampaigns] = useState([]);
  const [totalCampaigns, setTotalCampaigns] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [campaignsPagination, setCampaignsPagination] = useState({});
  const [loadingCampaigns, setLoadingCampaigns] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [defaultPageSizeT, setDefaultPageSizeT] = useState(10);
  const [transactionsPagination, setTransactionsPagination] = useState({});
  const [loadingTransactions, setLoadingTransactions] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isVisibleUpload, setIsVisibleUpload] = useState(false);
  const [imgProfile, setImgProfile] = useState("");
  const [img64, setImg64] = useState("");

  const onOpenModal = () => {
    console.log("open modal");
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setIsVisibleUpload(false);
  };

  const handleUploadModal = (e) => {
    console.log(e);
    if (e.target.files.length > 0) {
      if (
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpeg"
      ) {
        setImgProfile(e.target.files[0]);
        setIsVisibleUpload(true);
      } else {
        swal("Only files jpeg/png", {
          icon: "error",
          buttons: false,
        });
      }
    }
  };
  const onOpenEdit = () => {
    setIsModalVisible(true);
  };

  const onPickImage = (image) => {
    console.log("Picked image... ");
    console.log({ image });
  };

  const handleSearch = (e) => {
    console.log("Cause searched", e.target.value);
  };
  const getTransactions = useCallback(
    async (store_id, currentPage, pageSize) => {
      try {
        setLoadingTransactions(true);

        const response = await localAuth.getStoreTransactions(
          store_id,
          currentPage ? currentPage : 1,
          pageSize ? pageSize : defaultPageSizeT
        );
        if (response.success) {
          setTransactionsPagination({
            current: response.data.current_page,
            pageSize: pageSize ? pageSize : defaultPageSizeT,
            total: response.data.total_items,
            onChange: function (page, pageSize) {
              setDefaultPageSizeT(pageSize);
              getTransactions(store_id, page, pageSize);
            },
          });

          setTransactions(response.data.rows);
          setTotalTransactions(response.data.total_items);
        }

        setLoadingTransactions(false);
      } catch (error) {
        console.log({ error });
      }
    },
    []
  );

  const getCampaigns = useCallback(async (store_id, currentPage, pageSize) => {
    try {
      setLoadingCampaigns(true);

      const response = await localAuth.getStoreDonations(
        store_id,
        currentPage ? currentPage : 1,
        pageSize ? pageSize : defaultPageSize
      );

      if (response.success) {
        setCampaignsPagination({
          current: response.data.current_page,
          pageSize: pageSize ? pageSize : defaultPageSize,
          total: response.data.total_items,
          onChange: function (page, pageSize) {
            setDefaultPageSize(pageSize);
            getCampaigns(store_id, page, pageSize);
          },
        });

        setCampaigns(response.data.rows);
        setTotalCampaigns(response.data.total_items);
      }

      setLoadingCampaigns(false);
    } catch (error) {
      console.log({ error });
    }
  }, []);

  const onFinishEdit = async (values) => {
    console.log({ values });
    const data = {
      name: values.store_name,
      charities: [],
    };

    try {
      const response = await localAuth.updateStore(storeId, data);

      if (response.success) {
        swal("The store was updated!", {
          icon: "success",
          buttons: false,
        });
        setIsModalVisible(false);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getStoreInfo = useCallback(async () => {
    try {
      const response = await localAuth.getStore(storeId);
      if (response.success) {
        console.log(response.data);
        setStoreData(response.data);
        getCampaigns(response.data.id);
        getTransactions(response.data.id);
        setImg64("");
      }
    } catch (error) {
      console.log("Error getting store info");
      console.log(error);
      return error;
    }
  }, [router]);

  useEffect(async () => {
    let isMounted = true;
    if (isMounted) {
      getStoreInfo();
    }
    return () => {
      isMounted = false;
    };
  }, [getStoreInfo]);

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  // Add your onchange handler
  const onChangeFileHandler = async (e, name) => {
    console.log(dataURLtoFile(e, name));
    const fileToUpload = dataURLtoFile(e, name); //files[0];

    let data = new FormData();
    data.append("file", fileToUpload);

    let response = await fetch(
      process.env.REACT_APP_INSPIRED_URL + `/api/v1/stores/${storeId}/logo`,
      {
        method: "POST",
        headers: {
          apikey: process.env.REACT_APP_API_KEY,
        },
        body: data,
      }
    );

    response = await response.json();
    if (response.success) {
      swal("The image was changed!", {
        icon: "success",
        buttons: false,
      });
      setIsVisibleUpload(false);
      setImg64(e);
    } else {
      swal("error", {
        icon: "error",
        buttons: false,
      });
    }

    console.log("response...");
    console.dir(response);
  };

  const campaignsTitle = `${
    totalCampaigns === 1 ? "1 Campaign" : totalCampaigns + " Campaigns"
  }`;
  const transactionsTitle = `${
    totalTransactions === 1
      ? "1 Transaction"
      : totalTransactions + " Transactions"
  }`;

  return (
    <div>
      <Header />
      <section className="container">
        <Breadcrumb
          push="/stores"
          prevRoute="Stores"
          currentRoute="Store Detail"
        />
        {Object.keys(storeData).length !== 0 &&
          storeData.constructor !== {} && (
            <Store
              storeInfo={storeData}
              openModal={onOpenModal}
              openEdit={onOpenEdit}
              img64={img64}
              handleUploadModal={handleUploadModal}
            />
          )}

       
        <section className="container container--detail">
          <Block appearance="row">
            <Title text={campaignsTitle} />
          </Block>
          {campaigns.length > 0 && (
            <TableCampaigns
              columns={columnsCampaigns}
              rowKey={(record) => record.id}
              dataSource={campaigns}
              pagination={campaignsPagination}
              loading={loadingCampaigns}
            />
          )}
          <Block appearance="row">
            <Title text={transactionsTitle} />
          </Block>
          {transactions.length > 0 && (
            <TableTransactions
              columns={columnsTransactions}
              rowKey={(record) => record.id}
              dataSource={transactions}
              pagination={transactionsPagination}
              loading={loadingTransactions}
            />
          )}
        </section>
      </section>
      <FormEdit
        handleSearch={handleSearch}
        isModalVisible={isModalVisible}
        onFinishEdit={onFinishEdit}
        handleCancel={handleCancel}
        onPickImage={onPickImage}
        imageList={imageList}
        initialValues={{
          store_name: storeData.name,
          shopify_id: storeData.platform_store_id,
        }}
      />
      <ModalUpload
        key="modal-upload"
        isVisible={isVisibleUpload}
        handleCancel={handleCancel}
        imgProfile={imgProfile}
        onChangeFileHandler={onChangeFileHandler}
      />
    </div>
  );
}
