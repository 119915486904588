function PrintTabularData({ data = [] }) {
  return (
    <>
      {data.map(({ key = "", value = "" }, index) => (
        <p style={{ maxWidth: "70ch" }} key={index}>
          {key}
          {key ? ":" : ""} <span>{value}</span>
        </p>
      ))}
    </>
  );
}

export default function index({
  category,
  city,
  ein,
  location,
  id,
  organization_name,
  profile_level,
  created_at,
  updated_at,
  period_donations,
  total_donations,
  mission,
  state,
  logo_url,
}) {
  const data = [
    {
      key: "EIN",
      value: ein,
    },
    {
      key: "Location",
      value: state,
    },
    {
      key: "G.S. Level",
      value: profile_level,
    },
    {
      key: "Category",
      value: category,
    },
    {
      key: "Created",
      value: created_at,
    },
    {
      key: "Last Update",
      value: updated_at,
    },
    {
      key: "Period Donations",
      value: period_donations,
    },
    {
      key: "Total Donations",
      value: total_donations,
    },
  ];

  return (
    <div className="brand-detail-card">
      <div className="img__container">
        <img alt="brand" src={logo_url} />
      </div>
      <div>
        <h2>{organization_name}</h2>

        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <PrintTabularData data={data} />
          </div>
          <div style={{ flex: 1 }}>
            <PrintTabularData
              data={[
                {
                  key: "Description",
                  value: mission,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
