import { useState, useEffect, useCallback } from "react";
import Header from "../components/Layout/Header";

import Title from "components/Title";
import Block from "components/Block";
import ButtonAdd from "components/Buttons/BasicButton";
import TableCauseCollections from "components/Table";
import FormCreateCollection from "components/Forms/FormCreateCollection";

import { useRouter } from "util/router";
import localAuth from "util/local-auth";
import { formatCurrency } from "util/helpers";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Charities",
    dataIndex: "charities",
  },
  {
    title: "Donations (This Period)",
    dataIndex: "period_donations",
    render: (period_donations) => formatCurrency(period_donations, 1),
  },
  {
    title: "Total Donations",
    dataIndex: "total_donations",
    render: (total_donations) => formatCurrency(total_donations, 1),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: () => "—",
  },
];

export default function Collections() {
  const router = useRouter();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [causeCollections, setCauseCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCollections, setTotalCollections] = useState("");
  const [pagination, setPagination] = useState({});
  const [defaultPageSize, setDefaultPageSize] = useState(10);

  const onOpenCreateCollection = () => {
    setIsModalVisible(true);
  };

  const showCollectionDetails = (row) => {
    router.push(`/collections/${row.id}`);
  };

  const getCauseCollectionsDonations = useCallback(
    async (currentPage, pageSize) => {
      try {
        setLoading(true);

        const response = await localAuth.getCauseCollectionsDonations(
          currentPage ? currentPage : 1,
          pageSize ? pageSize : defaultPageSize
        );

        if (response.success) {
          setPagination({
            current: response.data.current_page,
            pageSize: pageSize ? pageSize : defaultPageSize,
            total: response.data.total_items,
            onChange: function (page, pageSize) {
              setDefaultPageSize(pageSize);
              getCauseCollectionsDonations(page, pageSize);
            },
          });

          setTotalCollections(response.data.total_items);
          setCauseCollections(response.data.rows);

          setLoading(false);
        }
      } catch (error) {
        console.log({ error });
      }
    },
    []
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      //searchCharities();
      getCauseCollectionsDonations();
    }
    return () => {
      isMounted = false;
    };
  }, [getCauseCollectionsDonations]);

  const title = `${totalCollections} Collections`;

  return (
    <>
      <Header />
      <section className="container">
        <Block appearance="row">
          <Title text={title} />
          <ButtonAdd
            name="Create New Collection"
            appearance="add-collection"
            icon
            onClick={onOpenCreateCollection}
          />
        </Block>
        <TableCauseCollections
          className={"data__table"}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={causeCollections}
          pagination={pagination}
          loading={loading}
          onRow={(row, rowIndex) => {
            return {
              onClick: (event) => {
                showCollectionDetails(row);
              }, // click row
            };
          }}
        />
        <FormCreateCollection
          isModalVisible={isModalVisible}
          title="Create New Cause Collection"
          setIsModalVisible={setIsModalVisible}
          getCauseCollectionsDonations={getCauseCollectionsDonations}
        />
      </section>
    </>
  );
}
