import React, { useEffect, useCallback, useState } from "react";
import localAuth from "util/local-auth";
import { Modal, Button, Form, Input, Popover } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import TableCheckbox from "components/TableCheckbox";
import swal from "sweetalert";
import ShowPagination from "components/ShowPagination";

const columns = [
  {
    title: "Image",
    dataIndex: "src",
    key: "src",
    render: (src) => <img src={src} className="logo" />,
    width: 50,
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: 250,
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
    width: "150px",
  },
  {
    title: "Info",
    dataIndex: "info",
    key: "info",
    width: "100px",
    render: (info, data) => (
      <Popover
        content={info}
        title={`ein: ${data.ein}`}
        overlayClassName="popover-content"
        placement="bottom"
      >
        <p className="more-info">More info</p>
      </Popover>
    ),
  },
];

export default function FormCreateCollection({
  isModalVisible,
  initialValues,
  title,
  setIsModalVisible,
  getCauseCollectionsDonations,
}) {
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); //id
  const [selectedRows, setSelectedRows] = useState([]);
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({});
  const DEFAULT_PAGE_SIZE = 10;

  const handleSearch = (e) => {
    if (e.target.value && e.target.value.length >= 4) {
      searchCharities(e.target.value);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    //setIsVisibleUpload(false);
    setImageList([]);
    form.resetFields();
  };

  const onChange = (rows, rowKeys) => {
    const newKey = rowKeys.filter((x) => !selectedRowKeys.includes(x));
    const newRow = rows.filter((x) => !selectedRows.includes(x));
    if (newKey.length > 0) {
      setSelectedRowKeys([...selectedRowKeys, newKey[0]]);
      return setSelectedRows([...selectedRows, newRow[0]]);
    }

    setSelectedRowKeys(rowKeys);
    return setSelectedRows(rows);
  };

  const onFinishCreateCollection = async (values) => {
    //values."cause_category": "C00"
    const data = {
      logo_url: "https://picsum.photos/200/120",
      description: values.cause_collection_description,
      title: values.cause_collection_name,
      active: true,
      charities: selectedRowKeys.length > 0 && selectedRowKeys,
    };

    console.log(data);

    try {
      const response = await localAuth.createCauseCollection(data);

      if (response.success) {
        swal("The cause collection was created!", {
          icon: "success",
          buttons: false,
        });
        setIsModalVisible(false);
        getCauseCollectionsDonations();
        //resetfields
        setImageList([]);
        form.resetFields();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (!isModalVisible) {
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setPagination({});
    }
  }, [isModalVisible]);

  const searchCharities = useCallback(async (value, currentPage, pageSize) => {
    console.log(currentPage, pageSize);
    try {
      if (value === undefined) return;

      const response = await localAuth.searchCharities(
        value,
        currentPage ? currentPage : 1,
        pageSize ? pageSize : DEFAULT_PAGE_SIZE
      );
      let logos = [];

      if (response.success) {
        setLoading(false);

        setPagination({
          current: response.data.current_page,
          pageSize: pageSize ? pageSize : DEFAULT_PAGE_SIZE,
          total: response.data.total_items,
          onChange: function (page, pageSize) {
            searchCharities(value, page, pageSize);
          },
        });

        response.data.rows.forEach((cause) => {
          logos.push({
            key: cause.id,
            src: cause.logo_url,
            title: cause.organization_name,
            size: 75,
            location: cause.city,
            info: cause.mission,
            ein: cause.ein,
          });
        });

        setImageList(logos);
      }
    } catch (error) {
      console.log({ error });
    }
  }, []);

  return (
    <Modal
      title={title}
      centered
      visible={isModalVisible}
      wrapClassName="FormModal"
    >
      <Form
        form={form}
        name={"form-create-collection"}
        layout={"vertical"}
        onFinish={onFinishCreateCollection}
        initialValues={initialValues}
      >
        <div>
          <Form.Item label={"Cause Collection Name"}>
            <Form.Item name="cause_collection_name">
              <Input placeholder="Cause Collection Name" />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="cause_collection_description"
            label="Cause Collection Description"
          >
            <Input.TextArea placeholder="Enter description..." />
          </Form.Item>

          <Form.Item label={"Add Causes"}>
            <p>You can add up to 6 causes to a collection</p>
            <ShowPagination
              show={
                pagination.current
                  ? pagination.current * pagination.pageSize
                  : 0
              }
              total={pagination.total ? pagination.total : 0}
            />
            <Form.Item name="add_causes_to_collection">
              <Input
                onChange={handleSearch}
                placeholder="Search something"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          </Form.Item>
          <TableCheckbox
            loading={loading}
            columns={columns}
            dataSource={imageList}
            onChange={onChange}
            max={6}
            selectedRowKeys={selectedRowKeys}
            selectedRows={selectedRows}
            pagination={pagination}
          />
        </div>

        <div className="submit-row">
          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn--save">
              Create
            </Button>
          </Form.Item>
          <Button
            onClick={handleCancel}
            htmlType="button"
            className="btn--cancel"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
