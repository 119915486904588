function PrintTabularData({ data = [] }) {
  return (
    <>
      {data.map(({ key = "", value = "" }, index) => (
        <p style={{ maxWidth: "70ch" }} key={index}>
          {key}
          {key ? ":" : ""} <span>{value}</span>
        </p>
      ))}
    </>
  );
}

export default function index({ id, title, description, logo_url, createdAt, updatedAt }) {
  const data = [
    {
      key: "",
      value: description,
    },
    {
      key: "Created",
      value: createdAt,
    },
    {
      key: "Last Update",
      value: updatedAt,
    },
  ];

  return (
    <div className="brand-detail-card">
      <div className="img__container">
        <img alt="brand" src={logo_url} />
      </div>
      <div>
        <h2>{title}</h2>

        <PrintTabularData data={data} />
      </div>
    </div>
  );
}
