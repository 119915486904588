import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Input, Dropdown, Menu, Select  } from "antd";
import { DownOutlined, ShoppingOutlined, EyeOutlined } from '@ant-design/icons';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
const { Option } = Select;

export default function Index({
  isModalVisible,
  onFinishEdit,
  handleCancel,
  initialValues,
  title,
  brands,
  type
}) {
  const [form] = Form.useForm();
  const [passRule, setPassRule] = useState(false)
  const detectChangePassword = () => {
    if(form.getFieldValue('password') != ''){
      setPassRule(true);
    }
  }
  return (
    <Modal
      title={title}
      centered
      visible={isModalVisible}
      wrapClassName="FormModal"
      destroyOnClose
    >
      <Form
        name={"form-add-brand"}
        layout={"vertical"}
        onFinish={onFinishEdit}
        initialValues={initialValues}
      >
        <div>
          <Form.Item label={"First Name"}>
            <Form.Item name="first_name"   rules={[
              {
                required: true,
                message: "Please enter first name",
              },
            ]}>
              <Input placeholder="First Name"/>
            </Form.Item>
          </Form.Item>

          <Form.Item label={"Last Name"}>
            <Form.Item name="last_name"   rules={[
              {
                required: true,
                message: "Please enter last name",
              },
            ]}>
              <Input placeholder="Last Name" />
            </Form.Item>
          </Form.Item>          

          <Form.Item label={"Email"} >
            <Form.Item name="email"rules={[
              {
                required: true,
                message: "Please enter email",
              },
            ]}>
              <Input placeholder="Email" />
            </Form.Item>
          </Form.Item>
          { type === 'add' || ( initialValues.brand !== undefined && initialValues.brand !== null) ? 
            <Form.Item label={"Brand"} >
              <Form.Item name="brand_id">
              <Select defaultValue={initialValues.brand ? initialValues.brand.id : null }>
                  {brands.map(({ id, name }) => (
                      <Option value={id}> {name} </Option>
                  ))}
                  </Select>
              </Form.Item>
            </Form.Item>
          : null
          }
        </div>
        {type === 'edit' ? <>
        <p>Only if you want change password fill this inputs</p>
        <Form.Item label={"Current Password"} >
            <Form.Item name="current_password" 
            onChange={detectChangePassword}
            >
              <Input.Password placeholder="Current Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/>
            </Form.Item>
          </Form.Item>
          <Form.Item label={"New Password"} >
            <Form.Item name="new_password" rules={[
                          ]}>
              <Input.Password placeholder="New Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/>
            </Form.Item>
          </Form.Item>
          <Form.Item label={"Confirm new Password"} >
            <Form.Item name="confirm_new_password"rules={[
              
              ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
            ]}>
              <Input.Password placeholder="Confirm new Password" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            </Form.Item>
          </Form.Item>
          </>
        : null
        }
        <div className="submit-row">
          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn--save">
              {type === 'add' ? 'Create' : 'Update' } & Close
            </Button>
          </Form.Item>
          <Button
            onClick={handleCancel}
            htmlType="button"
            className="btn--cancel"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
