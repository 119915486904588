import React from "react";
import { Form, Button, Input, Checkbox, Select } from "antd";

export default function BasicForm({
  checkbox,
  fields,
  buttons,
  name = "",
  formLayout = "vertical",
  form,
  initialValues = {},
  onFinish,
  handleStateChange,
  handleClick,
}) {

  return (
    <Form
      form={form}
      name={name}
      layout={formLayout}
      onFinish={onFinish}
      initialValues={initialValues}
      scrollToFirstError
    >
      <div className="checkbox__row">
        {checkbox &&
          checkbox.map((e, i) => (
            <Form.Item name={e.field_name} valuePropName="checked" key={i}>
              <Checkbox>{e.field_name}</Checkbox>
            </Form.Item>
          ))}
      </div>
      <div className="input__row">
        {fields &&
          fields.map((field, i) => (
            <>
              {(field.type === "text" || field.type === "email") && (
                <Form.Item label={field.field_label} key={i}>
                  <Form.Item name={field.field_id ? field.field_id : field.field_name}
                  rules={
                    field.required
                      ? [
                          {
                            required: true,
                            message: field.feedback_message,
                          },
                        ]
                      : null
                  }>
                    <Input placeholder={field.placeholder} />
                  </Form.Item>
                </Form.Item>
              )}
              {field.type === "select"  && (
                  <Form.Item label={field.field_label} key={i}>
                    <Form.Item name={field.field_id} rules={
                        field.required
                          ? [
                              {
                                required: true,
                                message: field.feedback_message,
                              },
                            ]
                          : null
                      }>
                      <Select onChange={handleStateChange}>
                        {field.options.map((option) => (
                          <Select.Option key={option.id} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                )}
             
            </>
          ))}
      </div>
      <div className="buttons_row">
        <Form.Item>
          {buttons &&
            buttons.map((button, i) => (
              <>
                {button.type === "button" && (
                  <Button
                    type={button.type}
                    htmlType={button.type}
                    id={`button${name}--${i}`}
                    key={i}
                    onClick={handleClick}
                  >
                    {button.text_name}
                  </Button>
                )}
                {button.type === "submit" && (
                  <Button
                    type={button.type}
                    htmlType={button.type}
                    id={`button${name}--${i}`}
                    key={i}
                 
                  >
                    {button.text_name}
                  </Button>
                )}
              </>
            ))}
        </Form.Item>
      </div>
    </Form>
  );
}
