import React, { useCallback, useEffect, useState } from "react";
import { Form as FormAnt } from "antd";
import ButtonAdd from "../components/Buttons/BasicButton";
import { useRouter } from "util/router";
import Header from "components/Layout/Header";
import DonationsTable from "components/Table";
import TransactionsTable from "components/Table";
import Block from "components/Block";
import Title from "components/Title";
import DetailBrandLogo from "../assets/images/detail-brand-logo.png";
import localAuth from "util/local-auth";
import CardBrandDetail from "components/CardBrandDetail";
import ModalUpload from "components/ModalUpload";
import StylingSettingsEditor from "components/Forms/StylingSettingsEditor";
import CampaignSettingsEditor from "components/Forms/CampaignSettingsEditor";
import FormAddStoreToBrand from "components/Forms/FormAddStoreToBrand";
import swal from "sweetalert";
import { formatCurrency } from "util/helpers";
import causes from "../data/causesFake";
import collections from "../data/collectionsFake";

const donationsColumns = [
  {
    title: "Logo",
    dataIndex: "img_url",
    key: "img_url",
    render: (img_url) => (
      <img src={img_url} alt="category img" className="img-category" />
    ),
  },
  {
    title: "Store Name",
    dataIndex: "name",
    key: "name",
    render: (name) => `${name}`,
  },
  {
    title: "Base Url",
    dataIndex: "base_url",
    key: "base_url",
    render: (base_url) => {
      return (
        <a href={base_url} target="_blank" rel="noreferrer">
          {base_url}
        </a>
      );
    },
  },
  {
    title: "Active Campaigns",
    dataIndex: "num_campaigns",
    key: "num_campaigns",
    render: (num_campaigns) => `${num_campaigns}`,
  },
  {
    title: "Transactions",
    dataIndex: "num_transactions",
    key: "num_transactions",
    render: (num_transactions) => `${num_transactions}`,
  },
  {
    title: "Donations (This Period)",
    dataIndex: "period_donations",
    key: "period_donations",
    render: (period_donations) => formatCurrency(period_donations, 1),
  },
  {
    title: "Total Donations",
    dataIndex: "total_donations",
    key: "total_donations",
    render: (total_donations) => formatCurrency(total_donations, 1),
  },
];

export const transactionsColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Store Name",
    dataIndex: "store_name",
    key: "store_name",
  },
  {
    title: "Donation",
    dataIndex: "donation",
    key: "donation",
    render: (donation) => formatCurrency(donation, 1),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Donation Period",
    dataIndex: "month",
    key: "month",
    render: (month, row) => `${month} ${row.year}`,
  },
];

function BrandDetail() {
  const router = useRouter();
  const brandId = router.query.id;
  const [brand, setBrand] = useState({});
  const [donations, setDonations] = useState([]);
  const [donationsPagination, setDonationsPagination] = useState({});
  const [totalDonations, setTotalDonations] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [
    visibleCampaingsSettingsEditor,
    setVisibleCampaingsSettingsEditor,
  ] = useState(false);
  const [loadingDonations, setLoadingDonations] = useState(false);
  const [isVisibleUpload, setIsVisibleUpload] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVisibleAddStore, setIsVisibleAddStore] = useState(false);
  const [imgProfile, setImgProfile] = useState("");
  const [img64, setImg64] = useState("");
  const [
    visibleStylingSettingsEditor,
    setVisibleStylingSettingsEditor,
  ] = useState(false);
  const [form] = FormAnt.useForm();
  const [transactions, setTransactions] = useState([]);
  const [transactionsPagination, setTransactionsPagination] = useState({});
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [defaultPageSizeT, setDefaultPageSizeT] = useState(10);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [stylingInitialValues, setStylingInitialValues] = useState({
    default_header: "You Live. We Give.",
    default_body_copy:
      "Your purchase donates <donation> to <charity> at no cost to you!",
    product_page_styling: "simple",
    default_location: "above",
    cart_page: "simple",
    order_status: "simple",
  });
  const [featuredCauses, setFeaturedCauses] = useState([]);
  const [excludedCauses, setExcludedCauses] = useState([]);
  const [excludedCausesVisible, setExcludedCausesVisible] = useState(false);
  const [editCausesMode, setEditCausesMode] = useState(false);
  const [
    initialValuesCampaignSettings,
    setInitialValuesCampaignSettings,
  ] = useState({});

  const getBrand = useCallback(async () => {
    try {
      const response = await localAuth.getBrand(brandId);

      if (response.success) {
        console.log(response.data);

        setBrand(response.data);
        setInitialValuesCampaignSettings({
          customer_search_on: response.data.global_settings.customer_search_on,
          excludedCauses: response.data.excluded_causes,
        });

        if (response.data.styling_settings) {
          setStylingInitialValues(response.data.styling_settings);
        }

        if (response.data.featured_causes) {
          setFeaturedCauses(response.data.featured_causes);
        }

        if (
          response.data.excluded_causes &&
          response.data.excluded_causes.length > 0
        ) {
          setExcludedCauses(response.data.excluded_causes);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  }, [brandId]);

  const getBrandDonations = useCallback(
    async (currentPage, pageSize) => {
      try {
        setLoadingDonations(true);

        const response = await localAuth.getBrandDonations(
          brandId,
          currentPage ? currentPage : 1,
          pageSize ? pageSize : defaultPageSizeT
        );

        if (response.success) {
          setDonationsPagination({
            current: response.data.current_page,
            pageSize: pageSize ? pageSize : defaultPageSizeT,
            total: response.data.total_items,
            onChange: function (page, pageSize) {
              setDefaultPageSizeT(pageSize);
              getBrandDonations(page, pageSize);
            },
          });

          setTotalDonations(response.data.total_items);
          setDonations(response.data.rows);
        }
        setLoadingDonations(false);
      } catch (error) {
        console.log({ error });
      }
    },
    [brandId]
  );

  const getBrandTransactions = useCallback(
    async (currentPage, pageSize) => {
      try {
        setLoadingTransactions(true);

        const response = await localAuth.getBrandTransactions(
          brandId,
          currentPage ? currentPage : 1,
          pageSize ? pageSize : defaultPageSize
        );

        if (response.success) {
          setTransactionsPagination({
            current: response.data.current_page,
            pageSize: pageSize ? pageSize : defaultPageSize,
            total: response.data.total_items,
            onChange: function (page, pageSize) {
              setDefaultPageSizeT(pageSize);
              getBrandTransactions(page, pageSize);
            },
          });

          setTotalTransactions(response.data.total_items);
          setTransactions(response.data.rows);
        }

        setLoadingTransactions(false);
      } catch (error) {
        console.log({ error });
      }
    },
    [brandId]
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getBrand();
      getBrandDonations();
      getBrandTransactions();
    }
    return () => {
      isMounted = false;
    };
  }, [getBrand, getBrandDonations, getBrandTransactions]);

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsVisibleUpload(false);
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  const handleSearch = (e) => {
    console.log(e.target.value);
  };

  const handleUploadModal = (e) => {
    console.log(e);
    if (e.target.files.length > 0) {
      if (
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpeg"
      ) {
        setImgProfile(e.target.files[0]);
        setIsVisibleUpload(true);
      } else {
        swal("Only files jpeg/png", {
          icon: "error",
          buttons: false,
        });
      }
    }
  };
  const onChangeFileHandler = async (e, name) => {
    console.log(dataURLtoFile(e, name));
    const fileToUpload = dataURLtoFile(e, name);

    let data = new FormData();
    data.append("file", fileToUpload);

    let response = await fetch(
      process.env.REACT_APP_INSPIRED_URL + `/api/v1/brands/${brandId}/logo`,
      {
        method: "POST",
        headers: {
          apikey: process.env.REACT_APP_API_KEY,
        },
        body: data,
      }
    );

    response = await response.json();
    console.log(response);
    if (response.success) {
      swal("The image was changed!", {
        icon: "success",
        buttons: false,
      });
      setIsVisibleUpload(false);
      setImg64(e);
    } else {
      swal("error", {
        icon: "error",
        buttons: false,
      });
    }
    setTimeout(() => swal.close(), 3000);
  };

  const toggleVisibleStylingSettingsEditor = () => {
    setVisibleStylingSettingsEditor(!visibleStylingSettingsEditor);
  };
  const toggleVisibleCampaingsSettingsEditor = () => {
    setVisibleCampaingsSettingsEditor(!visibleCampaingsSettingsEditor);
  };

  const toggleExcludedCauses = () => {
    setExcludedCausesVisible(!excludedCausesVisible);
  };
  const toggleEditCauses = () => {
    setEditCausesMode(!editCausesMode);
  };
  const onAddStoreToBrand = async ({ platform_store_id }) => {
    try {
      const data = { brand_id: brand.id };
      const response = await localAuth.linkStore(platform_store_id, data);

      console.log(response);
      if (response.success) {
        setIsVisibleAddStore(false);
        swal("The store was added to brand!", {
          icon: "success",
          buttons: false,
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const saveBrandStylingSettings = async (values) => {
    const data = {
      styling_settings: {
        cart_page: values.cart_page,
        default_body_copy: values.default_body_copy,
        default_header: values.default_header,
        default_location: values.default_location,
        order_status: values.order_status,
        product_page_styling: values.product_page_styling,
      },
    };

    try {
      const response = await localAuth.saveBrandStylingSettings(brandId, data);

      console.log(response);
      if (response.success) {
        setVisibleStylingSettingsEditor(false);
        swal("The brand settings were updated!", {
          icon: "success",
          buttons: false,
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const showStoreDetails = (row) => {
    console.log(row);
    router.push(`/store/${encodeURIComponent(row.platform_store_id)}`);
  };  

  const donationsTitle = `${
    totalDonations === 1 ? "1 Store" : totalDonations + " Stores"
  }`;
  const transactionsTitle = `${
    totalTransactions === 1
      ? "1 Transaction"
      : totalTransactions + " Transactions "
  }`;

  return (
    <>
      <Header>
        <></>
      </Header>
      <section className="container">
        {Object.keys(brand).length !== 0 && brand.constructor !== {} && (
          <CardBrandDetail
            handleOpenStylingSettings={toggleVisibleStylingSettingsEditor}
            handleOpenCampaignSettings={toggleVisibleCampaingsSettingsEditor}
            img64={img64}
            img={brand.img_url ? brand.img_url : DetailBrandLogo}
            onChange={handleUploadModal}
            {...brand}
          />
        )}

        <Block appearance="spaced-inline" />
        <Block appearance="row">
          <Title text={donationsTitle} />
          <ButtonAdd
            name="Add Store"
            appearance="add"
            onClick={() => setIsVisibleAddStore(true)}
            icon
          />
        </Block>

        <DonationsTable
          className={"data__table"}
          columns={donationsColumns}
          rowKey={(record) => record.id}
          dataSource={donations}
          pagination={donationsPagination}
          loading={loadingDonations}
          onRow={(row, rowIndex) => {
            return {
              onClick: (event) => {
                showStoreDetails(row);
              }, // click row
            };
          }}          
        />

        <div style={{ padding: "12px 0" }}>
          <Block appearance="row">
            <Title text={transactionsTitle} />
          </Block>
          <TransactionsTable
            columns={transactionsColumns}
            rowKey={(record) => record.id}
            dataSource={transactions}
            loading={loadingTransactions}
            pagination={transactionsPagination}
          />
        </div>

        <ModalUpload
          key="modal-upload"
          isVisible={isVisibleUpload}
          handleCancel={handleCancel}
          imgProfile={imgProfile}
          onChangeFileHandler={onChangeFileHandler}
        />

        <FormAddStoreToBrand
          form={form}
          key="modal-add-store"
          open={isVisibleAddStore}
          isModalVisible={isVisibleAddStore}
          onFinish={onAddStoreToBrand}
          handleCancel={() => {
            form.resetFields();
            setIsVisibleAddStore(false);
          }}
          initialValues={{}}
        />
        <StylingSettingsEditor
          key="styling-editor"
          initialValues={stylingInitialValues}
          onFinishEdit={(values) => saveBrandStylingSettings(values)}
          form={form}
          toggleVisible={visibleStylingSettingsEditor}
          handleCancel={toggleVisibleStylingSettingsEditor}
        />
        <CampaignSettingsEditor
          key="campaign-settings-editor"
          handleSearch={handleSearch}
          causes={causes}
          form={form}
          brandId={brandId}
          collections={collections}
          featuredCauses={featuredCauses}
          toggleVisible={visibleCampaingsSettingsEditor}
          handleCancel={toggleVisibleCampaingsSettingsEditor}
          setVisibleCampaingsSettingsEditor={setVisibleCampaingsSettingsEditor}
          editCausesMode={editCausesMode}
          toggleEditCauses={toggleEditCauses}
          initialValues={initialValuesCampaignSettings}
          refresh={getBrand}
          toggleExcludedCauses={toggleExcludedCauses}
          excludedCausesVisible={excludedCausesVisible}
          setExcludedCausesVisible={setExcludedCausesVisible}
        />
      </section>
    </>
  );
}

export default BrandDetail;
