import {React, useEffect, useState} from "react";
import Header from "../components/Layout/Header";
import Title from "../components/Title";
import Block from "../components/Block";
import { Row, Card } from 'antd';
import { Container } from '@material-ui/core';
import localAuth from "../util/local-auth";
import Grid from '@material-ui/core/Grid';
// import Table from "../components/Table";
import { Table } from "antd";
import { formatCurrency, formatDate } from "util/helpers";

export default function Dashboard() {
  const [cardMetrics, setCardMetrics] = useState([]);
  const [donationTable, setDonationTable] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalOrdersAmount, setTotalOrdersAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const labelsMetrics = [
    { label: 'Active Campaigns', id: 'num_active_campaign'},
    { label: 'Stores (Brands)', id: 'num_stores'},
    { label: 'Total Completed Donations (Paid)', id: 'total_donations'},
    { label: 'Avg. Items per Qualifying Order*', id: 'num_active_campaign'},
    { label: 'Avg. Spend per Qualifying Order*', id: 'avg_spend', type: 'currency'},
    { label: 'Avg Donation per Qualifying Order*', id: 'avg_donation', type:'currency'},
  ];
  const columnsDonationTable = [
    {
        title: "Date",
        dataIndex: "date",
        render: (id) => formatDate(id, 'onlyDate'),
    },
    {
      title: "# of Orders",
      dataIndex: "orders_by_day",
      render: (id) => `${id}`,
    },
    {
      title: "Stores",
      dataIndex: "store_name",
      render: (id) => `${id}`,
    },
    {
      title: "Eligible Donations($)",
      dataIndex: "donation_amount",
      render: (id) => formatCurrency(id, 1, ''),
    },
    {
      title: 'Paid Donations',
      dataIndex: 'paid_donations',
      render: (id) => `${id}`,
    }
    
  ]
  useEffect( () => {
    setLoading(true);
    localAuth.getMetrics().then( response => {
      let aux = [];
      if (response.success){
        for (let i = 0; i < labelsMetrics.length; i++) {
          const element = labelsMetrics[i];
          if(Object.hasOwnProperty.call(response.data[0], element.id)){
            aux.push({
              label: element.label,
              value: element.type && element.type === 'currency'? formatCurrency(response.data[0][element.id], 1, '') : response.data[0][element.id]
            });
          }
        }
        setCardMetrics([...aux]);
      }
    })
    .catch( e => console.log(e));
    
    localAuth.getDonations().then( response => {
      if (response.success){
        let auxTotalOrders = 0;
        let auxTotalOrdersAmount = 0;
        response.data.forEach(element => {
          auxTotalOrders += parseInt(element.orders_by_day);
          auxTotalOrdersAmount += parseFloat(element.donation_amount);
        });
        setTotalOrders(auxTotalOrders)
        setTotalOrdersAmount(auxTotalOrdersAmount)
        setDonationTable([...response.data]);
      }
      setLoading(false);
    })
    .catch( e => console.log(e));

  }, []);
  
  return (
    <>
      <Header>
        <></>
      </Header>
      <section className="container">
        <Block appearance="row">
          <Title text="Dashboard" />
        </Block>
        <p style={{width: '100%'}}>Platform Metrics</p>
        <Container style={{marginTop: 30, padding:0 }}>

          <Grid container spacing={3} alignContent="flex-start" alignContent="flex-start" style={{marginLeft:0, marginRight: 0}}>
            {
              cardMetrics.length > 0 ? 
                cardMetrics.map( (card, i) => (
                  <Grid item xs={4} key={i}>
                    <Card style={{padding: 0}}>
                      <p style={{fontSize: '14px'}}>{card.label}</p>
                      <p style={{fontSize: '32px', }}>{card.value}</p>
                    </Card>
                  </Grid>
                ))
              :
              <Card>
                   <></>
              </Card>  
            }
          </Grid>
        </Container>
        <p style={{marginTop: 10, padding:0, fontSize:'12px', marginBottom: 15 }}>*qualifying order: order with a donation</p>

        <h1 style={{fontSize: '22px', fontWeight: 'bold', marginBottom: 15}}>Total Transactions (Donations) <span style={{fontWeight: 'normal'}}>{totalOrders}( {formatCurrency(totalOrdersAmount, 1, '')})</span> <span style={{float: 'right', fontWeight: 'normal'}}>Last 14 Days</span></h1>
        <Table
          columns={columnsDonationTable}
          dataSource={donationTable}
          pagination={false}
          loading={loading}
        />
      </section>
    </>
  );
}
