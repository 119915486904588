export const formatCurrency = (rawPrice, divided = 100, currency = "USD") => {
  if (isNaN(rawPrice)) return "";

  const config = {
    style: "currency",
    currency: "USD",
  };

  return (
    new Intl.NumberFormat("en-US", config).format(rawPrice / divided) +
    " " +
    currency
  );
};

export const toTitleCase = (sentence) =>
  sentence
    .toLowerCase()
    .split(" ")
    .map(([w, ...x]) => w.toUpperCase() + x.join(""))
    .join(" ");

export const formatDate = (rawDate, preset = "full") => {
  if (!rawDate) return "";

  const configs = {
    full: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    },
    full12: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    },
    onlyDate: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour12: false,
    },
    monthYear: {
      year: "numeric",
      month: "long",
    },
  };

  return new Intl.DateTimeFormat("en-US", configs[preset]).format(
    new Date(rawDate)
  );
};
