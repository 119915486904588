import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import { Switch, Route, Router } from "util/router.js";
import { AuthContext, useAuth } from "util/auth";

import LoginPage from "./login";
import DashboardPage from "./dashboard";
import StoresPage from "./stores";
import PaymentsPage from "./payments";
import UsersPage from "./users";
import CustomersPage from "./consumers";
import BrandsPage from "./brands";
import BrandPage from "./brandDetail";
import AllCharitiesPage from "./all-charities";
import CampaignsPage from "./campaigns";
import CollectionsPage from "./collections";
import CompanyPage from "./company";
import ConsumerDetailPage from "./consumerDetail";
import OrdersPage from "./orders";
import StorePage from "./storeDetail";
import ProjectsPage from "./projects";
import CharitiesPage from "./charities";

import CollectionDetailPage from "./collectionDetail";
import CharityDetailPage from "./charityDetail";
import OrderDetailsPage from "./orderDetail";

import DashboardLayout from "components/Layout/DashboardLayout";

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { authToken } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        authToken ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

function App() {
  const existingToken = JSON.parse(localStorage.getItem("access_token"));
  const [authToken, setAuthToken] = useState(existingToken);

  const setTokens = (data) => {
    localStorage.setItem("access_token", JSON.stringify(data));
    setAuthToken(data);
  };
  return (
    <AuthContext.Provider value={{ authToken, setAuthToken: setTokens }}>
      <Router>
        <Switch>
          <Route exact path={"/"} component={LoginPage} />
          <PrivateRoute
            exact
            path={"/dashboard"}
            component={DashboardPage}
            layout={DashboardLayout}
          />

          <PrivateRoute
            exact
            path={"/stores"}
            component={StoresPage}
            layout={DashboardLayout}
          />

          <PrivateRoute
            exact
            path={"/brands"}
            component={BrandsPage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/brand/:id"}
            component={BrandPage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/store/:id"}
            component={StorePage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/all-charities"}
            component={AllCharitiesPage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/collections"}
            component={CollectionsPage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/collections/:id"}
            component={CollectionDetailPage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/projects"}
            component={ProjectsPage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/charities"}
            component={CharitiesPage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/charities/:id"}
            component={CharityDetailPage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/campaigns"}
            component={CampaignsPage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/companies/:id"}
            component={CompanyPage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/consumers"}
            component={CustomersPage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/consumer/:id"}
            component={ConsumerDetailPage}
            layout={DashboardLayout}
          />
           <PrivateRoute
            exact
            path={"/users"}
            component={UsersPage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/orders"}
            component={OrdersPage}
            layout={DashboardLayout}
          />
          <PrivateRoute
            exact
            path={"/order/:id"}
            component={OrderDetailsPage}
            layout={DashboardLayout}
          />          
           <PrivateRoute
            exact
            path={"/payments"}
            component={PaymentsPage}
            layout={DashboardLayout}
          />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
