import React, { useState } from "react";
import SectionLogin from "./../components/SectionLogin";
import { useAuth } from "../util/auth";
import { Form } from "antd";
import localAuth from "./../util/local-auth";
import { Redirect } from "react-router-dom";

const Login = () => {
  const [form] = Form.useForm();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [authFailed, setAuthFailed] = useState(false);
  const { setAuthToken } = useAuth();

  const onFinish = (values) => {
    if (values) {
      postLogin(values);
    }
  };

  const postLogin = async (values) => {
 
    const data = {
      email: values.email,
      password: values.password,
    };
    try {
      const response = await localAuth.login(data, false);

      if (response && response.success) {
        const token = response.data.token;
        const brand_id = response.data.brand_id;
        const brand_name = response.data.brand_name;
        localStorage.setItem("access_token", token);
        localStorage.setItem("brand_id", brand_id);
        localStorage.setItem("brand_name", brand_name);

        setAuthToken(token);
        setLoggedIn(true);
      }else{
        setAuthFailed(true)
      }
    } catch (error) {
    
      setAuthFailed(true)
    }
  
  };

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }

  return <SectionLogin onFinish={onFinish} form={form}  authFailed={authFailed}/>;
};

export default Login;
