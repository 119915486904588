import React from "react";
import ModalWrapper from "../../ModalWrapper";
import { Radio, Form } from "antd";
import { InputNumber, Button } from "antd";
export default function index({ open, onCloseModal, onFinish }) {
  return (
    <ModalWrapper open={open} onCloseModal={onCloseModal}>
      <div className="form-edit-global-rating">
        <Form
          name="form-edit-global-rating"
          layout={"vertical"}
          onFinish={onFinish}
        >
          <h4>Edit Global Rating</h4>
          <div className="ratings-container">
            <Form.Item name="b-corp-certified" label="B-Corp Certified">
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="wwg-certified" label="EWG Certified">
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="bb-accredited" label="BBB Accredited">
              <Radio.Group>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="BBB Score"
              name="bbb-score"
              rules={[
              
                {
                  type: "number",
                  min: 0.001,
                  message: "BBB Score is not valid",
                },

                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value <= 3000 && value >= 0.001) {
                      return Promise.resolve();
                    }
                    if (value > 3000) {
                      return Promise.reject("BBB Score is not valid");
                    }
                    if (typeof value !== Number) {
                      return Promise.reject("");
                    }
                  },
                }),
              ]}
            >
              <InputNumber placeholder="Enter BBB Score" />
            </Form.Item>

            <Form.Item
              label="Glassdoor Rating"
              name="glassdoor-rating"
              rules={[
                
                {
                  type: "number",
                  min: 0.001,
                  message: "Glassdoor Rating is not valid",
                },

                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value <= 3000 && value >= 0.001) {
                      return Promise.resolve();
                    }
                    if (value > 3000) {
                      return Promise.reject("Glassdoor Rating is not valid");
                    }
                    if (typeof value !== Number) {
                      return Promise.reject("");
                    }
                  },
                }),
              ]}
            >
              <InputNumber placeholder="Enter Glassdoor Rating" />
            </Form.Item>
            <Form.Item
              label="ESG Score"
              name="esg-score"
              rules={[
               
                {
                  type: "number",
                  min: 0.001,
                  message: "ESG Score is not valid",
                },

                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value <= 3000 && value >= 0.001) {
                      return Promise.resolve();
                    }
                    if (value > 3000) {
                      return Promise.reject("ESG Score is not valid");
                    }
                    if (typeof value !== Number) {
                      return Promise.reject("");
                    }
                  },
                }),
              ]}
            >
              <InputNumber placeholder="Enter ESG Score" />
            </Form.Item>
          </div>
          <div className="submit-row">
            <Form.Item>
              <Button type="primary" htmlType="submit" className="btn--save">
                Save
              </Button>
            </Form.Item>
            <button onClick={onCloseModal} className="btn--cancel">
              Cancel
            </button>
          </div>
        </Form>
      </div>
    </ModalWrapper>
  );
}
