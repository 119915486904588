 const collections = [
  {
    name: "Collection 1",
    isDefault: true,
    charities: [{
        id:"1",
       name: "Charity Name 1"
    },
    {
        id:"2",
       name: "Charity Name 2"
    },
    {
        id:"3",
       name: "Charity Name 3"
    },
    {
        id:"4",
       name: "Charity Name 4"
    },
    {
        id:"5",
       name: "Charity Name 5"
    },
    {
        id:"6",
       name: "Charity Name 6"
    }
    ],
  },
  {
    name: "Collection 2",
    isDefault: false,
    charities: [{
        id:"1",
       name: "Charity Name 1"
    },
    {
        id:"2",
       name: "Charity Name 2"
    },
    {
        id:"3",
       name: "Charity Name 3"
    },
    {
        id:"4",
       name: "Charity Name 4"
    },
    {
        id:"5",
       name: "Charity Name 5"
    },
    {
        id:"6",
       name: "Charity Name 6"
    }
    ],
  },
];
export default collections;