import React from "react";
import { Input } from "antd";
const { Search } = Input;

const InputSearch = ({
  placeholder,
  onChange,
  onSearch,
  appearance = "normal",
}) => {
  return (
    <Search
      placeholder={placeholder}
      enterButton="Search"
      className={`input-search input-search--${appearance}`}
      onSearch={onSearch}
      onChange={onChange}
    />
  );
};

export default InputSearch;
