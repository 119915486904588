import React from "react";
import { Button } from "antd";

export default function index({ title, description, isDefault, logo_url , handleDefaultCause, defaultCause, id}) {
  return (
    <div className="featured-causes-card">
      <img src={logo_url} alt={logo_url} className="picture" />
      <div className="info">
        <h6>{title}</h6>
        {description && (
          <span>
            {description.length > 100
              ? `${ description.substring(0, 100) } ...`
              : description}
          </span>
        )}
      </div>
      <div className="button">
        {defaultCause === id ? (
          <Button className="default">Default</Button>
        ) : (
          <Button onClick={()=> handleDefaultCause(id)}>Set as Default</Button>
        )}
      </div>
    </div>
  );
}
