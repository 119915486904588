import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import TableStores from "../components/Table";
import Block from "../components/Block";
import Title from "../components/Title";
import localAuth from "../util/local-auth";
import { Form } from "antd";
import ModalWrapper from "../components/ModalWrapper";
import BasicForm from "../components/Forms/BasicForm";
import ButtonAdd from "../components/Buttons/BasicButton";
import FormFieldsAddCategory from "../data/FormFields/addCategory.json";
import { formatCurrency } from "util/helpers";
import FilterInput from "../components/Table/filterInput";
import { usePrevious } from "util/hooks";
import { useRouter } from "../util/router";

const columns = [
  {
    title: "Logo",
    dataIndex: "img_url",
    render: (img_url) => (
      <img src={img_url} alt="category img" className="img-category" />
    ),
  },
  {
    title: "Store Name",
    dataIndex: "name",
    render: (name) => `${name}`,
  },
  {
    title: "Store Url",
    dataIndex: "base_url",
    render: (base_url) => {
      return (
        <a href={base_url} target="_blank" rel="noreferrer">
          {base_url}
        </a>
      );
    },
  },
  {
    title: "Active Campaigns",
    dataIndex: "num_campaigns",
    key: "num_campaigns",
    render: (num_campaigns) => `${num_campaigns}`,
  },
  {
    title: "Transactions",
    dataIndex: "num_transactions",
    key: "num_transactions",
    render: (num_transactions) => `${num_transactions}`,
  },
  {
    title: "Donations (This Period)",
    dataIndex: "period_donations",
    key: "period_donations",
    render: (period_donations) => formatCurrency(period_donations, 1),
  },
  {
    title: "Total Donations",
    dataIndex: "total_donations",
    key: "total_donations",
    render: (total_donations) => formatCurrency(total_donations, 1),
  },
];

function Stores() {
  const router = useRouter();
  const [storesDonations, setStoresDonations] = useState(false);
  const [totalDonations, setTotalDonations] = useState("");
  const [pagination, setPagination] = useState({});
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");

  const prevSearch = usePrevious(searchText);

  const onCloseModal = () => setOpenModal(false);
  const onOpenModal = () => {
    setOpenModal(true);
  };

  const getStoresDonations = useCallback(
    async (currentPage, pageSize) => {
      try {
        setLoading(true);
        console.log(searchText);
        const response = await localAuth.getStoresDonations(
          currentPage ? currentPage : 1,
          pageSize ? pageSize : defaultPageSize,
          searchText
        );

        if (response.success) {
          setPagination({
            current: response.data.current_page,
            pageSize: pageSize ? pageSize : defaultPageSize,
            total: response.data.total_items,
            onChange: function (page, pageSize) {
              setDefaultPageSize(pageSize);
              getStoresDonations(page, pageSize);
            },
          });

          setTotalDonations(response.data.total_items);
          setStoresDonations(response.data.rows);
        }
        setLoading(false);
      } catch (error) {
        console.log({ error });
      }
    },
    [searchText]
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getStoresDonations();
    }
    return () => {
      isMounted = false;
    };
  }, [getStoresDonations]);

  const onFinishAddInstructor = async (values) => {
    if (values) {
      //addCategory(values)
    }
  };
  const onSearch = async (value) => {
    console.log(value);
    setSearchText(value);
    if (value !== prevSearch) getStoresDonations();
  };

  const onChangeSearch = ({ target }) => {
    const value = target.value;
    if (!value) {
      onSearch(value);
    }
  };

  const showStoreDetails = (row) => {
    router.push(`/store/${encodeURIComponent(row.platform_store_id)}`);
  };

  const onChange = (pagination, filters) => {};
  return (
    <>
      <Header>
        <></>
      </Header>
      <section className="container">
        <Block appearance="row">
          <Title text="Stores" />
          <ButtonAdd name="Add Store" appearance="add" icon />
        </Block>
        {/* SEARCH - FILTER */}
        <div className="store-actions">
          <div className="store-actions__search">
            <FilterInput onSearch={onSearch} onChange={onChangeSearch} />
          </div>
          <div className="store-actions__filter"></div>
        </div>
        <TableStores
          className="tableStores"
          onRow={(row, rowIndex) => {
            return {
              onClick: (event) => {
                showStoreDetails(row);
              }, // click row
            };
          }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={storesDonations}
          pagination={pagination}
          loading={loading}
          onChange={onChange}
        />
      </section>
      <ModalWrapper open={openModal} onCloseModal={onCloseModal}>
        <BasicForm
          onFinish={onFinishAddInstructor}
          handleClick={onCloseModal}
          form={form}
          name="form__addCategory"
          {...FormFieldsAddCategory}
        />
      </ModalWrapper>
    </>
  );
}

export default Stores;
