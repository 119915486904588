import React, { useEffect, useState, useCallback } from "react";
import Header from "../components/Layout/Header";
import Title from "../components/Title";
import Block from "../components/Block";
import ButtonAdd from "../components/Buttons/BasicButton";
import FormAddUser from "../components/Forms/FormAddUser";
import { Button, Space, Tooltip } from "antd";
import IconDelete from "./../assets/images/Delete.png";
import IconEdit from "./../assets/images/Edit.png";
import TableRoles from "../components/Table";
import localAuth from "../util/local-auth";
import { Form } from "antd";
import swal from "sweetalert";

export default function Users() {
  const brand_id = localStorage.getItem("brand_id");
  const [form] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [users, setUsers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [userSelected, setUserSelected] = useState({});
  const [pagination, setPagination] = useState({
    pageSize: 10,
    currentPage: 1,
  });
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [title, setTitle] = useState("Add User");
  const [typeForm, setTypeForm] = useState("add");
  // const [userCreated, setUserToCreate] = useState({});
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (id, record, index) => `${index + 1}`,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      render: (first_name) => `${first_name}`,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      render: (last_name) => `${last_name}`,
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (email) => `${email}`,
    },
    {
      title: "Brand",
      dataIndex: "brand",
      render: (brand) => `${brand ? brand.name : ""}`,
    },

    {
      title: "",
      dataIndex: "",
      align: "right",
      width: "25%",
      render: (text, record, index) => (
        <Space size="middle" style={{ position: "relative", top: "5px" }}>
          {brand_id > 0 ? null : (
            <Tooltip
              title="Delete"
              style={{ fontFamily: "Helvetica", color: "red" }}
            >
              <Button
                type="link"
                shape="circle"
                className="btn-action"
                onClick={(e) => handleDelete(record.id)}
                icon={
                  <img
                    src={IconDelete}
                    style={{ width: "30px" }}
                    alt="Delete User"
                  />
                }
              />
            </Tooltip>
          )}
          {/*  <Tooltip title="Edit">
            <Button
              type="link"
              shape="circle"
              className="btn-action"
              onClick={(e) => {
                toggleOpenModal("Edit User", record);
                setTypeForm("edit");
                console.log(record);
              }}
              icon={
                <img src={IconEdit} style={{ width: "30px" }} alt="Edit User" />
              }
            />
          </Tooltip> */}
        </Space>
      ),
    },
  ];

  const openEditModal = (record) => {
    console.log(record);
    toggleOpenModal("Edit User", record);
    setTypeForm("edit");
  };

  useEffect(() => {
    console.log(brand_id);
    reloadTable(pagination.pageSize, pagination.currentPage);
  }, []);
  const tableOnChange = async (pag) => {
    setPagination({ ...pagination, currentPage: pag.current });
    await reloadTable(pag.pageSize, pag.current);
  };
  const reloadTable = async (pageSize, currentPage) => {
    localAuth
      .getUsers(currentPage, pageSize)
      .then((response) => {
        setUsers(response.data.rows);
        setPagination({
          currentPage: response.data.current_page,
          pageSize: pagination.pageSize ? pagination.pageSize : defaultPageSize,
          total: response.data.total_items,
        });
      })
      .catch((e) => console.log("error", e));

    if (brand_id == 0)
      localAuth
        .getBrandsBasic()
        .then((res) => setBrands(res.data))
        .catch((e) => console.log(e));
  };

  const toggleOpenModal = (title, record) => {
    setTitle(title);
    console.log(record);
    form.resetFields();
    if (record) {
      setUserSelected(record);
    } else {
      setUserSelected({});
    }

    setOpenModal(!openModal);
  };

  const onFinishEnterUser = async (data) => {
    try {
      if (typeForm === "edit") {
        const response = await localAuth.updateUser(data);

        if (response.success) {
          swal(`The user was updated with success`, {
            icon: "success",
            buttons: true,
          }).then((e) => form.resetFields());
        }
      } else {
        const response = await localAuth.createUser(data);

        if (response.success) {
          swal(
            `The user was created with success. We sent an email with credentials.`,
            {
              icon: "success",
              buttons: true,
            }
          ).then((e) => form.resetFields());
        }
      }
    } catch (error) {
      console.log({ error });
    } finally {
      reloadTable(pagination.pageSize, pagination.currentPage);
      setOpenModal(false);
    }
  };

  const handleDelete = (userId) => {
    swal(`Are you sure to delete this user?`, {
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Ok",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then(async (e) => {
      if (e) {
        await localAuth.deleteUser(userId);
        reloadTable(pagination.pageSize, pagination.currentPage);
      }
    });
  };
  return (
    <>
      <Header>
        <></>
      </Header>
      <section className="container">
        <Block appearance="row">
          <Title text="Users" />
          {brand_id > 0 ? null : (
            <ButtonAdd
              name="Add User"
              appearance="add"
              onClick={(e) => {
                toggleOpenModal("Add User");
                setTypeForm("add");
              }}
              icon
            />
          )}
        </Block>

        <TableRoles
          className={"data__table"}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={users}
          pagination={pagination}
          onChange={tableOnChange}
          loading={false}
          deleteUser={handleDelete}
          onRow={(row, rowIndex) => {
            return {
              onClick: (event) => {
                openEditModal(row);
              }, // click row
            };
          }}
        />
      </section>

      <FormAddUser
        isModalVisible={openModal}
        onFinishEdit={onFinishEnterUser}
        handleCancel={toggleOpenModal}
        form={form}
        initialValues={userSelected}
        title={title}
        brands={brands}
        type={typeForm}
      />
    </>
  );
}
