import React from "react";
import { Form, Input, Button } from "antd";

const FormLogin = ({  onFinish ,authFailed}) => {

  return (
    <Form
      name="form_login"
      className={`login-form `}
      layout="vertical"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      {authFailed && <p className="auth-failed-message">These credentials do not match our records.</p> }
      <Form.Item
        className="login-input"
        label="Email"
        name="email"
        rules={[
          { type: "email", message: "Invalid E-mail" },
          { required: true, message: "Please input your Email" },
        ]}
      >
        <Input  placeholder="Enter email"/>
      </Form.Item>
      <Form.Item
        className="login-input"
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your Password" }]}
      >
        <Input type="password"  placeholder="Enter password" />
      </Form.Item>

      <Form.Item>
        <Button  htmlType="submit" className="login-form-button">
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};
export default FormLogin;
