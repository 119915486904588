import React from "react";
import { Tag } from "antd";

export default function index({ label, onClose, value }) {
  return (
    <Tag closable onClose={() => onClose(value)}>
      {label}
    </Tag>
  );
}
