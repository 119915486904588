import React, { useState } from "react";
import { Table } from "antd";
import Tag from "../Tag";
import swal from "sweetalert";

export default function TableCheckbox({
  columns,
  dataSource,
  onChange,
  max,
  selectedRowKeys,
  selectedRows,
  pagination,
}) {
  const exists = (keys) => {
    const intersection = keys.filter((x) => !selectedRowKeys.includes(x));
    if (intersection.length === 0) return true;
    return false;
  };

  const onChangeSelect = (selectedRowKey, selectedRow) => {
    if (max) {
      if (exists(selectedRowKey) || selectedRowKeys.length < max) {
        return onChange(selectedRow, selectedRowKey);
      }
      return handleOnMaxImages();
    }
    return onChange(selectedRow, selectedRowKey);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onChangeSelect,
  };

  const deleteTag = (value) => {
    const newCollections = selectedRowKeys.filter((item) => item !== value); //id
    const newTags = selectedRows.filter((item) => item.key !== value);
    onChange(newTags, newCollections);
  };

  const handleOnMaxImages = () => {
    swal(`You can choose up to ${max} causes`, {
      icon: "warning",
      buttons: false,
    });
  };

  return (
    <div>
      <div className="Tags">
        <p>
          Selected {selectedRows.length} {max && `/${max}`}{" "}
        </p>
        {selectedRows.map((item, index) => (
          <Tag
            label={item.title}
            key={`tag-${index}-${item.title}`}
            onClose={deleteTag}
            value={item.key}
          />
        ))}
      </div>
      <div className="table-picker">
        <Table
          dataSource={dataSource}
          columns={columns}
          rowSelection={rowSelection}
          showHeader={false}
          pagination={pagination}
          scroll={dataSource.length > 10 && { y: 740 }}
        />
      </div>
    </div>
  );
}
