import React from "react";
import { Modal, Button, Form, Input, Radio } from "antd";

export default function index({
  toggleVisible,
  onFinishEdit,
  handleCancel,
  initialValues,
  form
}) {
  return (
    <Modal
      title="Global Styling"
      centered
      visible={toggleVisible}
      wrapClassName="FormEditor"
    >
      <Form
      form={form}
        name={"form-styling-editor"}
        layout={"vertical"}
        onFinish={onFinishEdit}
        initialValues={initialValues}
      >
        <h2>Customize Message</h2>
        <div>
          <Form.Item label={"Default Header"}>
            <Form.Item name="default_header">
              <Input placeholder="" />
            </Form.Item>
          </Form.Item>

          <Form.Item label={"Default Body Copy"}>
            <Form.Item name="default_body_copy">
              <Input placeholder="" />
            </Form.Item>
          </Form.Item>
          <Form.Item 
          name="product_page_styling" 
          label="Product Page Styling">
        <Radio.Group>
          <Radio value="simple">Simple</Radio>
          <Radio value="framed">Framed</Radio>
     
        </Radio.Group>
      </Form.Item>
          
      <Form.Item name="default_location" label="Default Location">
        <Radio.Group>
          <Radio value="above">Above “Add to Cart”</Radio>
          <Radio value="below">Below “Add to Cart”</Radio>
        
        </Radio.Group>
      </Form.Item>

      <h2>Other Pages</h2>

      <Form.Item name="cart_page" label="Cart Page">
        <Radio.Group>
          <Radio value="simple">Simple</Radio>
          <Radio value="framed">Framed</Radio>
        
        </Radio.Group>
      </Form.Item>
      <Form.Item name="order_status" label="Order Status">
        <Radio.Group >
         
           <Radio value="simple">Simple</Radio>
          <Radio value="framed">Framed</Radio>
        
        </Radio.Group>
      </Form.Item>
        </div>

        <div className="submit-row">
          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn--save btn--save-purple">
              Save & Close
            </Button>
          </Form.Item>
          <Button
            onClick={handleCancel}
            htmlType="button"
            className="btn--cancel"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
