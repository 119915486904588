import React, { useState, useCallback, useEffect } from "react";
import localAuth from "util/local-auth";
import { Modal, Button, Form, Input, Popover } from "antd";
import FeaturedCausesCard from "../../FeaturedCausesCard";
import { SearchOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import TableExcludedCauses from "components/TableCheckbox";
import swal from "sweetalert";
import FormEditCauses from "../FormEditCauses";

export default function CampaignSettingsEditor({
  toggleVisible,
  handleCancel,
  initialValues,
  featuredCauses,
  brandId,
  setVisibleCampaingsSettingsEditor,
  form,
  refresh,
  toggleEditCauses,
  editCausesMode,
  toggleExcludedCauses,
  excludedCausesVisible,
  setExcludedCausesVisible,
}) {
  const [loading, setLoading] = useState(false);
  const [charitiesToExclude, setCharitiesToExclude] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); //id
  const [selectedRows, setSelectedRows] = useState([]);
  const [causeCollections, setCauseCollections] = useState([]);
  const [causeCollectionsSelected, setCauseCollectionSelected] = useState([]);
  const [individualCausesSelected, setIndividualCausesSelected] = useState([]);
  const [individualCauses, setIndividualCauses] = useState([]);
  const [causesIds, setCausesIds] = useState([]);
  const [collectionsIds, setCollectionsIds] = useState([]);
  const [individualCausesPagination, setIndividualCausedPagination] = useState(
    {}
  );
  const [excludedCausesPagination, setExcludedCausedPagination] = useState({});
  const [causeCollectionsPagination, setCauseCollectionsPagination] = useState(
    {}
  );
  const [defaultCause, setDefaultCause] = useState(false)
  const [maximum, setMaximum] = useState(false);
  const DEFAULT_PAGE_SIZE = 10;

  const columns = [
    {
      title: "Image",
      dataIndex: "logo_url",
      key: "logo_url",
      render: (logo_url) => <img src={logo_url} className="logo" />,
      width: 50,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 250,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: "150px",
    },
    {
      title: "Info",
      dataIndex: "info",
      key: "info",
      width: "100px",
      render: (info, data) => (
        <Popover
          content={info}
          title={`ein: ${data.ein}`}
          overlayClassName="popover-content"
          placement="bottom"
        >
          <p className="more-info">More info</p>
        </Popover>
      ),
    },
  ];

  const columnsIndividualCauses = [
    {
      title: "Image",
      dataIndex: "logo_url",
      key: "logo_url",
      render: (logo_url) => <img src={logo_url} className="logo" alt="" />,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      dataIndex: "location",
      key: "location",
    },
    {
      dataIndex: "id",
      key: "key",
      render: (id, row) => (
        <Button
          onClick={() => {
            setIndividualCausesSelected([...individualCausesSelected, row]);
            setCausesIds([id, ...causesIds]);
            removeFeaturedCauses(id, id);
          }}
        >
          Add
        </Button>
      ),
    },
  ];
  const causeCollectionsColumns = [
    {
      dataIndex: "logo_url",
      key: "logo_url",
      width: "10%",
      render: (logo_url) => (
        <div>
          <img src={logo_url} alt="" className="logo_collections" />
        </div>
      ),
    },
    {
      dataIndex: "title",
      key: "title",
      width: "75%",
      render: (title, row) => {
        let idx = 0;
        let charities, firstCharities;
        if (row.charities) {
          charities = row.charities.slice(0, 5);
          firstCharities = charities.map((charity) => (
            <span key={row.id + "-" + idx++}>
              {charity.organization_name}
              {charity === charities[charities.length - 1] ? "" : ", "}
            </span>
          ));

          return (
            <>
              <h3>{title}</h3>
              {firstCharities}
              {row.charities.length > 4 && "..."}
            </>
          );
        } else return <h3>{title}</h3>;
      },
    },
    {
      dataIndex: "id",
      key: "id",
      width: "10%",
      render: (id, row) => {
        return (
          <Button
            onClick={() => {
              setCauseCollectionSelected([...causeCollectionsSelected, row]);
              removeFeaturedCauses(id);
              setCollectionsIds([id, ...collectionsIds]);
            }}
          >
            Add
          </Button>
        );
      },
    },
  ];
  const handleCharitiesToExclude = (e) => {
    if (e.target.value && e.target.value.length >= 4) {
      searchCharitiesToExclude(e.target.value);
    }
  };

  const handleCharitiesToInclude = (e) => {
    if (e.target.value && e.target.value.length >= 4) {
      searchCharitiesToInclude(e.target.value);
    }
  };

  const handleCollectionsToInclude = (e) => {
    if (e.target.value && e.target.value.length >= 4) {
      searchCollectionsToInclude(e.target.value);
    }
  };

  //REMOVE FROM TABLE
  const removeFeaturedCauses = (id, i = false) => {
    let items = [];
    if (i) {
      items = individualCauses.filter((item) => item.id !== id);
      setIndividualCauses(items);
      return;
    }
    items = causeCollections.filter((item) => item.id !== id);
    setCauseCollections(items);
  };

  //REMOVE FROM DATA
  const removeSelectedFeaturedCauses = (id, i = false) => {
    let items = [],
      ids = [];
    if (i) {
      items = individualCausesSelected.filter((item) => item.id !== id);
      ids = causesIds.filter((e) => e !== id);
      setCausesIds(ids);
      setIndividualCausesSelected(items);
      return;
    }
    items = causeCollectionsSelected.filter((item) => item.id !== id);
    ids = causesIds.filter((e) => e !== id);
    setCollectionsIds(ids);
    setCauseCollectionSelected(items);
  };

  const onChange = (rows, rowKeys) => {
    const newKey = rowKeys.filter((x) => !selectedRowKeys.includes(x));
    const newRow = rows.filter((x) => !selectedRows.includes(x));
    if (newKey.length > 0) {
      setSelectedRowKeys([...selectedRowKeys, newKey[0]]);
      return setSelectedRows([...selectedRows, newRow[0]]);
    }

    setSelectedRowKeys(rowKeys);
    return setSelectedRows(rows);
  };

  const saveBrandGlobalSettings = async (values) => {
    const data = {
      global_settings: {
        customer_search_on:
          values && values.customer_search_on != undefined
            ? values.customer_search_on
            : false,
      },
      excluded_causes: selectedRowKeys,
    };

    console.log("Data to update");
    console.log(data);
    try {
      const response = await localAuth.saveBrandGlobalCauses(brandId, data);

      if (response.success) {
        setVisibleCampaingsSettingsEditor(false);
        setExcludedCausesVisible(false);
        form.resetFields();
        swal("The brand global causes were updated!", {
          icon: "success",
          buttons: false,
        });
        //refresh
        refresh();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const saveBrandGlobalCauses = async (featuredCauses) => {
    const data = {
      featured_causes: featuredCauses,
      excluded_causes: selectedRowKeys,
    };

    console.log("Data to update");
    console.log(data);

    try {
      const response = await localAuth.saveBrandGlobalCauses(brandId, data);

      if (response.success) {
        toggleEditCauses();

        swal("The brand global causes were updated!", {
          icon: "success",
          buttons: false,
        });

        //refresh
        refresh();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  // Function to  Get Featured Causes Id's
  const getCausesId = () => {
    let idsCauses = causesIds.filter((e) => e !== undefined);
    let idsCollections = collectionsIds.filter((e) => e !== undefined);

    saveBrandGlobalCauses({
      collections: idsCollections,
      charities: idsCauses,
    });
  };

  const searchCollectionsToInclude = useCallback(
    async (value, currentPage, pageSize) => {
      try {
        console.log("HERE searchCollectionsToInclude() " + value);

        if (value === undefined) return;

        const response = await localAuth.getCauseCollectionByBrand(
          brandId,
          currentPage ? currentPage : 1,
          pageSize ? pageSize : DEFAULT_PAGE_SIZE,
          value
        );

        if (response.success) {
          console.log(response.data);
          setCauseCollectionsPagination({
            current: response.data.current_page,
            pageSize: pageSize ? pageSize : DEFAULT_PAGE_SIZE,
            total: response.data.total_items,
            onChange: function (page, pageSize) {
              searchCollectionsToInclude(value, page, pageSize);
            },
          });
          //exclude selected collections
          const newArray = response.data.rows.filter((collection) => {
            return !causeCollectionsSelected.find(
              (item) => collection.id === item.id
            );
          });
          setCauseCollections(newArray);
        }
      } catch (error) {
        console.log({ error });
      }
    },
    [causeCollectionsSelected]
  );

  const searchCharitiesToExclude = useCallback(
    async (value, currentPage, pageSize) => {
      console.log("searchCharitiesToExclude() ... " + value);
      try {
        if (value === undefined) return;

        const response = await localAuth.searchCharities(
          value,
          currentPage ? currentPage : 1,
          pageSize ? pageSize : DEFAULT_PAGE_SIZE
        );
        let logos = [];

        if (response.success) {
          setLoading(false);

          setExcludedCausedPagination({
            current: response.data.current_page,
            pageSize: pageSize ? pageSize : DEFAULT_PAGE_SIZE,
            total: response.data.total_items,
            onChange: function (page, pageSize) {
              searchCharitiesToExclude(value, page, pageSize);
            },
          });

          response.data.rows.forEach((cause) => {
            logos.push({
              key: cause.id,
              logo_url: cause.logo_url,
              title: cause.organization_name,
              location: cause.city,
              info: cause.mission,
            });
          });

          setCharitiesToExclude(logos);
        }
      } catch (error) {
        console.log({ error });
      }
    },
    []
  );

  const searchCharitiesToInclude = useCallback(
    async (value, currentPage, pageSize) => {
      console.log("searchCharitiesToInclude() ... " + value);
      try {
        if (value === undefined) return;

        const response = await localAuth.searchCharities(
          value,
          currentPage ? currentPage : 1,
          pageSize ? pageSize : DEFAULT_PAGE_SIZE
        );
        let causes = [];

        if (response.success) {
          //setLoading(false);

          setIndividualCausedPagination({
            current: response.data.current_page,
            pageSize: pageSize ? pageSize : DEFAULT_PAGE_SIZE,
            total: response.data.total_items,
            onChange: function (page, pageSize) {
              searchCharitiesToInclude(value, page, pageSize);
            },
          });

          response.data.rows.forEach((cause) => {
            causes.push({
              id: cause.id,
              logo_url: cause.logo_url,
              title: cause.organization_name,
              location: cause.city,
              info: cause.mission,
            });
          });
          //exclude selected individualCauses
          const newArray = causes.filter((cause) => {
            return !individualCausesSelected.find(
              (item) => cause.id === item.id
            );
          });

          /*  console.log({ individualCausesSelected });
          console.log({ newArray }, { causes }); */

          setIndividualCauses(newArray);
        }
      } catch (error) {
        console.log({ error });
      }
    },
    [individualCausesSelected]
  );

  useEffect(() => {
    const length =
      individualCausesSelected.length + causeCollectionsSelected.length;
    console.log(length);
    if (length > 2) {
      //clean tables
      setCauseCollections([]);
      setIndividualCauses([]);
      setIndividualCausedPagination({});
      setCauseCollectionsPagination({});
      //clean search
      form.resetFields();

      return setMaximum(true);
    }
    return setMaximum(false);
  }, [individualCausesSelected, causeCollectionsSelected]);

  useEffect(() => {
    //current saved featuredCauses
    if ((featuredCauses && featuredCauses.length > 0) || editCausesMode) {
      let items = featuredCauses.filter((item) => item.is_collection);
      setCauseCollectionSelected(items);
      setCollectionsIds(items.map((item) => item.id));

      items = featuredCauses.filter((item) => !item.is_collection);
      setIndividualCausesSelected(items);
      setCausesIds(items.map((item) => item.id));
    }

    if (!toggleVisible) {
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setCharitiesToExclude([]);
      //searchCollectionsToInclude();
      setExcludedCausedPagination({});
    } else {
      if (initialValues.excludedCauses?.length > 0) {
        const newArrayKeys = [],
          newArrayRows = [];
        initialValues.excludedCauses.map((item) => newArrayKeys.push(item.id));
        initialValues.excludedCauses.map((item) =>
          newArrayRows.push({
            title: item.organization_name,
            key: item.id,
            location: item.city,
            info: item.mission,
          })
        );
        setSelectedRowKeys(newArrayKeys);
        setSelectedRows(newArrayRows);
      }
    }
  }, [toggleVisible, editCausesMode]);

  const resetFeaturedCauses = () => {
    setCauseCollectionSelected([]);
    setIndividualCausesSelected([]);
    setIndividualCauses([]);
    setCharitiesToExclude([]);
    form.resetFields();
  };

  const handleDefaultCause = (id) =>{
    console.log("DEFAULT CAUSE ID:", id)
    setDefaultCause(id)
  }

  return (
    <Modal
      title={`${!editCausesMode ? "Global Causes" : "Edit Causes"}`}
      centered
      visible={toggleVisible}
      wrapClassName="FormModal"
    >
      {!editCausesMode ? (
        <Form
          form={form}
          name={"campaign-settings-editor"}
          layout={"vertical"}
          onFinish={saveBrandGlobalSettings}
          initialValues={initialValues}
        >
          {!excludedCausesVisible ? (
            <>
              <section>
                <div className="row">
                  <h2>Featured Causes</h2>
                  <Button onClick={toggleEditCauses}>Edit Causes</Button>
                </div>
 
                {featuredCauses.map((el) => (
                  <FeaturedCausesCard defaultCause={defaultCause} handleDefaultCause={handleDefaultCause} key={el.id} {...el}  />
                ))}

                <Form.Item
                  valuePropName="checked"
                  label="Activate Customer Search "
                  name="customer_search_on"
                >
                  <Checkbox>Customer Search On</Checkbox>
                </Form.Item>
                <div className="column">
                  <h2>Excluded Causes</h2>

                  <Button onClick={toggleExcludedCauses}>
                    {" "}
                    Edit Exclude List
                  </Button>
                </div>
              </section>
            </>
          ) : (
            <section>
              <Form.Item label={"Excluded Causes"}>
                <p>
                  Search for and Add Causes Categories to Brand’s Excluded
                  Causes list.
                </p>
                <Form.Item name="excluded_causes">
                  <Input
                    onChange={handleCharitiesToExclude}
                    placeholder="Search something"
                    prefix={<SearchOutlined />}
                  />
                </Form.Item>
              </Form.Item>

              <TableExcludedCauses
                rowKey={(record) => record.id}
                loading={loading}
                columns={columns}
                dataSource={charitiesToExclude}
                onChange={onChange}
                selectedRowKeys={selectedRowKeys}
                selectedRows={selectedRows}
                pagination={excludedCausesPagination}
              />
            </section>
          )}

          <div className="submit-row">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="btn--save btn--save-purple"
              >
                Save & Close
              </Button>
            </Form.Item>
            <Button
              onClick={
                excludedCausesVisible ? toggleExcludedCauses : handleCancel
              }
              htmlType="button"
              className="btn--cancel"
            >
              Cancel
            </Button>
          </div>
        </Form>
      ) : (
        <FormEditCauses
          causeCollectionsColumns={causeCollectionsColumns}
          toggleEditCauses={toggleEditCauses}
          form={form}
          onFinish={getCausesId}
          resetFeaturedCauses={resetFeaturedCauses}
          loading={loading}
          columns={columnsIndividualCauses}
          handleSearchCharities={handleCharitiesToInclude}
          handleSearchCollections={handleCollectionsToInclude}
          removeFeaturedCauses={removeSelectedFeaturedCauses}
          individualCausesPagination={individualCausesPagination}
          causeCollectionsData={causeCollections}
          individualCausesSelected={individualCausesSelected}
          individualCauses={individualCauses}
          causeCollectionsSelected={causeCollectionsSelected}
          causeCollectionsPagination={causeCollectionsPagination}
          maximum={maximum}
        />
      )}
    </Modal>
  );
}
