


export function inspiredApiRequest(path, method = "GET", data, returnAllResponse) {

  //const TOKEN_STORAGE_KEY = JSON.parse(localStorage.getItem("access_token"));
  //const accessToken = (path ==="user/login" ) ? false: TOKEN_STORAGE_KEY;

  return fetch(process.env.REACT_APP_INSPIRED_URL+`/api/v1${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json",
      apikey: process.env.REACT_APP_API_KEY,
      Authorization: localStorage.getItem("access_token"), 
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {

 
      if (response.status === "error") {
        // Automatically signout user if accessToken is no longer valid
        if (response.code === "auth/invalid-user-token") {
          //fakeAuth.signout();
          //TODO: call signout()
        }

        throw new CustomError(response.code, response.message);
      } else {
        return (returnAllResponse) ? response : (response);
      }
    }).catch((error) => {
      console.log("Error calling endpoint");
      console.log(error);
    });
}



export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}
