import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import TableCauses from "../components/Table";
import Block from "../components/Block";
import Title from "../components/Title";
import localAuth from "../util/local-auth";
import { Button, Space, Tooltip } from "antd";
import IconDelete from "./../assets/images/Delete.png";
import IconEdit from "./../assets/images/Edit.png";
import FilterInput from "components/Table/filterInput";
import { usePrevious } from "util/hooks";

const columns = [
  {
    title: "Logo",
    dataIndex: "logo_url",
    render: (logo_url) => (
      <img src={logo_url} alt="Org Img" className="img-cause" />
    ),
  },
  {
    title: "Organization Name",
    dataIndex: "organization_name",
    render: (name) => `${name}`,
  },
  {
    title: "Ein",
    dataIndex: "ein",
    render: (ein) => `${ein}`,
  },
  {
    title: "City",
    dataIndex: "city",
    render: (city) => `${city}`,
  },

  /* {
    title: "",
    dataIndex: "",
    align: "right",
    width: "15%",
    render: () => (
      <Space size="middle" style={{ position: "relative", top: "5px" }}>
        <Tooltip
          title="Delete"
          style={{ fontFamily: "Helvetica", color: "red" }}
        >
          <Button
            type="link"
            shape="circle"
            className="btn-action"
            icon={
              <img
                src={IconDelete}
                style={{ width: "30px" }}
                alt="Delete Category"
              />
            }
          />
        </Tooltip>
        <Tooltip title="Edit">
          <Button
            type="link"
            shape="circle"
            className="btn-action"
            icon={
              <img
                src={IconEdit}
                style={{ width: "30px" }}
                alt="Edit Category"
              />
            }
          />
        </Tooltip>
      </Space>
    ),
  }, */
];

function Causes() {
  const [allCauses, setCauses] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");

  const prevSearch = usePrevious(searchText);

  /* const getCharities = useCallback(async (currentPage, pageSize) => {
    try {
      setLoading(true);

      const response = await localAuth.getCharities(
        currentPage ? currentPage : 1,
        pageSize ? pageSize : defaultPageSize
      );

      if (response.success) {
        setPagination({
          current: response.data.current_page,
          pageSize: pageSize ? pageSize : defaultPageSize,
          total: response.data.total_items,
          onChange: function (page, pageSize) {
            getCharities(page, pageSize);
          },
        });

        setLoading(false);

        const causes = response.data.rows.filter(
          (category) => category.organization_name !== undefined
        );

        setCauses(causes);
      }
    } catch (error) {
      console.log({ error });
    }
  }, []); */

  const searchCharities = useCallback(
    async (value, currentPage, pageSize) => {
      console.log(currentPage, pageSize);
      try {
        if (value === undefined) return;

        const response = await localAuth.searchCharities(
          value,
          currentPage ? currentPage : 1,
          pageSize ? pageSize : defaultPageSize
        );
        let logos = [];

        if (response.success) {
          setLoading(false);

          setPagination({
            current: response.data.current_page,
            pageSize: pageSize ? pageSize : defaultPageSize,
            total: response.data.total_items,
            onChange: function (page, pageSize) {
              searchCharities(value, page, pageSize);
            },
          });

          response.data.rows.forEach((cause) => {
            logos.push({
              key: cause.id,
              src: cause.logo_url,
              title: cause.organization_name,
              size: 75,
              location: cause.city,
              info: cause.mission,
              ein: cause.ein,
            });
          });

          const causes = response.data.rows.filter(
            (category) => category.organization_name !== undefined
          );
          setCauses(causes);
        }
      } catch (error) {
        console.log({ error });
      }
    },
    [defaultPageSize]
  );

  const onSearch = async (value) => {
    console.log(value);
    setSearchText(value);
    if (value !== prevSearch) searchCharities(value);
  };

  const onChangeSearch = ({ target }) => {
    const value = target.value;
    if (!value) {
      onSearch(value);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      searchCharities("");
    }
    return () => {
      isMounted = false;
    };
  }, [searchCharities]);

  return (
    <>
      <Header>
        <></>
      </Header>
      <section className="container">
        <Block appearance="row">
          <Title text="All Charities" />
        </Block>
        {/* SEARCH - FILTER */}
        <div className="store-actions">
          <div className="store-actions__search">
            <FilterInput onSearch={onSearch} onChange={onChangeSearch} />
          </div>
          <div className="store-actions__filter"></div>
        </div>
        <TableCauses
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={allCauses}
          pagination={pagination}
          loading={loading}
        />
      </section>
    </>
  );
}

export default Causes;
