import React from "react";
import { Modal, Button, Form, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ImagePicker from "../../ImagePicker";

export default function index({
  isModalVisible,
  onFinishEdit,
  handleCancel,
  onPickImage,
  imageList,
  initialValues,
  handleSearch
}) {
  return (
    <Modal
      title="Edit Store"
      centered
      visible={isModalVisible}
      wrapClassName="FormModal"
    >
      <Form
        name={"form-edit-store"}
        layout={"vertical"}
        onFinish={onFinishEdit}
        initialValues={initialValues}
      >
        <div>
          <Form.Item label={"Store Name"}>
            <Form.Item name="store_name">
              <Input placeholder="Store Name" />
            </Form.Item>
          </Form.Item>
         
          <Form.Item label={"Shopify ID"} className="shopify-id-input">
            <Form.Item name="shopify_id">
              <Input disabled placeholder="ID" />
            </Form.Item>
          </Form.Item>

          <Form.Item label={"Add Featured Causes"}>
            <p>You can add up to 3 causes to a store</p>
            <Form.Item name="featured_causes">
              <Input
                onChange={handleSearch}
                placeholder="Search something"
                prefix={<SearchOutlined />}
              />
            </Form.Item>
          </Form.Item>
          <div className="img-picker-container">
          <ImagePicker
            images={imageList}
            onPick={onPickImage}
            multiple={true}
          />
        </div>
        </div>

        <div className="submit-row">
          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn--save">
              Save & close
            </Button>
          </Form.Item>
          <Button
            onClick={handleCancel}
            htmlType="button"
            className="btn--cancel"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
}