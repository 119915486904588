import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";

export default function ButtonEdit({ event, appearance=false }) {
  return (
    <Button className="Btn__edit" icon={<EditOutlined />} onClick={event}>
      Edit {appearance && (appearance)}
    </Button>
  );
}
