import React from "react";
import { Modal, Button, Form, Input } from "antd";

export default function index({
  isModalVisible,
  onFinishEdit,
  handleCancel,
  initialValues,
}) {
  return (
    <Modal
      title="Add Brand"
      centered
      visible={isModalVisible}
      wrapClassName="FormModal"
    >
      <Form
        name={"form-add-brand"}
        layout={"vertical"}
        onFinish={onFinishEdit}
        initialValues={initialValues}
      >
        <div>
          <Form.Item label={"Brand Name"}>
            <Form.Item name="brand_name"   rules={[
              {
                required: true,
                message: "Please enter brand name",
              },
            ]}>
              <Input placeholder="Brand Name" />
            </Form.Item>
          </Form.Item>

          <Form.Item label={"Email"} >
            <Form.Item name="brand_email"rules={[
              {
                required: true,
                message: "Please enter email",
              },
            ]}>
              <Input placeholder="Email" />
            </Form.Item>
          </Form.Item>

          <Form.Item label={"Contact Name"} >
            <Form.Item name="brand_contact_name"rules={[
              {
                required: true,
                message: "Please enter contact name",
              },
            ]}>
              <Input placeholder="Contact Name" />
            </Form.Item>
          </Form.Item>
        </div>

        <div className="submit-row">
          <Form.Item>
            <Button type="primary" htmlType="submit" className="btn--save">
              Create & Close
            </Button>
          </Form.Item>
          <Button
            onClick={handleCancel}
            htmlType="button"
            className="btn--cancel"
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
