import React, { useCallback, useEffect, useState } from "react";
import localAuth from "../util/local-auth";
import TableCustomerDetail from "../components/Table";
import { useRouter } from "util/router";
import Header from "../components/Layout/Header";
import Title from "../components/Title";
import Block from "../components/Block";
import { formatDate } from '../util/helpers';

const columnsTransactions = [
  {
    title: "Order ID",
    dataIndex: "id",
    render: (id) => `${id}`,
  },
  {
    title: "Order Date",
    dataIndex: "order_date",
    render: (order_date) => `${order_date}`,
  },
  {
    title: "Store Name",
    dataIndex: "store_name",
    render: (store_name) => `${store_name}`,
  },
  {
    title: "Cause Name",
    dataIndex: "cause_name",
    render: (cause_name, row) => `${ row.redemption_date == null ? '' : (row.selected_cause || row.selected_charity)}`,
  },
  {
    title: "Elegible Donation",
    dataIndex: "elegible_donation",
    render: (elegible_donation) => `$ ${elegible_donation}`,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status) => `${status}`,
  },
  {
    title: "Donation Period",
    dataIndex: "period",
    render: (period) => `${period}`,
  },
];

export default function ConsumerDetail() {
  const router = useRouter();
  const [customerName, setCustomerName] = useState([]);
  const [loading, setLoading] = useState(false);
  const customerId = router.query.id;
  const [transactionsPagination, setTransactionsPagination] = useState({});
  const [customerTransactions, setCustomerTransactions] = useState([]);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [customer, setCustomer] = useState({});
  const getCustomer = useCallback(async () => {
    try {
      setLoading(true);
      const response = await localAuth.getCustomer(customerId);
      if (response.success) {
        setCustomerName(
          response.data.first_name + " " + response.data.last_name
        );
        setCustomer(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log({ error });
    }
  }, []);

  const getCustomerTransactions = useCallback(
    async (customerId, currentPage, pageSize) => {
      try {
        setLoading(true);
        const response = await localAuth.getCustomerOrders(
          customerId,
          currentPage ? currentPage : 1,
          pageSize ? pageSize : defaultPageSize
        );
        if (response.success) {
          setTransactionsPagination({
            current: response.data.current_page,
            pageSize: pageSize ? pageSize : defaultPageSize,
            total: response.data.total_items,
            onChange: function (page, pageSize) {
              console.log(page);
              setDefaultPageSize(pageSize);
              getCustomerTransactions(customerId, page, pageSize);
            },
          });

          setCustomerTransactions(response.data.rows);
        }
        setLoading(false);
      } catch (error) {
        console.log({ error });
      }
    },
    []
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCustomer();
      getCustomerTransactions(customerId);
    }
    return () => {
      isMounted = false;
    };
  }, [getCustomer, getCustomerTransactions]);
  return (
    <div>
      <Header />

      <section className="container">
        <Block appearance="row">
          {customerName && <Title text={customerName} />}
        </Block>
        <div>
          <table style={{width: '100%', margin:'30px 0'}}>
            <tr>
              <td style={{width:'15%'}}><strong style={{fontWeight:'bold'}}>ID</strong></td>
              <td style={{width:'35%'}}>{customer.platform_customer_id}</td>
              <td style={{width:'15%'}}><strong style={{fontWeight:'bold'}}>First order date</strong></td>
              <td style={{width:'35%'}}>{formatDate(customer.firstOrder, 'full12')}</td>
            </tr>
            <tr>
              <td style={{width:'15%'}}><strong style={{fontWeight:'bold'}}>Email</strong></td>
              <td style={{width:'35%'}}>{customer.email}</td>
              <td style={{width:'15%'}}><strong style={{fontWeight:'bold'}}>Last order date</strong></td>
              <td style={{width:'35%'}}>{formatDate(customer.lastOrder, 'full12')}</td>
            </tr>
            <tr>
              <td style={{width:'15%'}}><strong style={{fontWeight:'bold'}}>Phone</strong></td>
              <td style={{width:'35%'}}>{customer.phone}</td>
              <td style={{width:'15%'}}><strong style={{fontWeight:'bold'}}>Most recent cause</strong></td>
              <td style={{width:'25%'}}>{customer.most_recent_cause}</td>
            </tr>
          </table>

        </div>
        
        <TableCustomerDetail
          columns={columnsTransactions}
          rowKey={(record) => record.id}
          dataSource={customerTransactions}
          pagination={transactionsPagination}
          loading={loading}
        />
      </section>
    </div>
  );
}
