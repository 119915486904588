import React, { Component } from "react";
import { Modal, Form, Input } from "antd";
import AvatarEditor from "react-avatar-editor";

export default class ModalUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      allowZoomOut: false,
      position: { x: 0.5, y: 0.5 },
      scale: 1,
      rotate: 0,
      borderRadius: 0,
      preview: null,
      width: 350,
      height: 350,
      isVisible: false,
    };
  }

  setEditorRef = (editor) => {
    if (editor) {
      this.editor = editor;
    }
  };

  handlePositionChange = (position) => {
    this.setState({ position });
  };

  handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  handleSave = (data) => {
    console.log(data);
    const img = this.editor.getImageScaledToCanvas().toDataURL();
    const rect = this.editor.getCroppingRect();
    this.setState({
      preview: {
        img,
        rect,
        /* scale: this.state.scale,
        width: this.state.width,
        height: this.state.height,
        borderRadius: this.state.borderRadius, */
      },
    });
    this.props.onChangeFileHandler(img, this.editor.props.image.name);
  };

  logCallback(e) {
    console.log("callback", e);
  }

  render() {
    const {
      isVisible,
      handleCancel,
      imgProfile,
      onChangeFileHandler,
    } = this.props;
    return (
      <Modal
        key="modal-upload"
        visible={isVisible}
        destroyOnClose={true}
        wrapClassName="UploadModal"
        title={"Update photo"}
        onCancel={handleCancel}
        footer={[
          <input
            type="button"
            onClick={this.handleSave}
            value="Save"
            className="save"
          />,
        ]}
      >
        <Form initialValues={{ scale: 1 }}>
          <div className="AvatarEditor" key="editor">
            <AvatarEditor
              ref={this.setEditorRef}
              scale={parseFloat(this.state.scale)}
              width={this.state.width}
              height={this.state.height}
              position={this.state.position}
              onPositionChange={this.handlePositionChange}
              rotate={parseFloat(this.state.rotate)}
              borderRadius={this.state.width / (100 / this.state.borderRadius)}
              onLoadFailure={this.logCallback.bind(this, "onLoadFailed")}
              onLoadSuccess={this.logCallback.bind(this, "onLoadSuccess")}
              onImageReady={this.logCallback.bind(this, "onImageReady")}
              image={imgProfile}
              key="avatar-editor"
            />
          </div>
          <div className="AvatarEditor" key="zoom">
            <Form.Item label="Zoom" name="scale" key="item-scale">
              <Input
                key="input-scale"
                name="input-scale"
                type="range"
                onChange={this.handleScale}
                min={this.state.allowZoomOut ? "0.1" : "1"}
                max="2"
                step="0.01"
                className="slider"
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    );
  }
}
