import React from "react";
import { Table } from "antd";

const index = ({
  columns,
  rowKey,
  dataSource,
  pagination,
  loading,
  onChange,
  className="",
  scroll =768,
  onRow = () => {}
}) => (
  <Table
    scroll={{ x: scroll }}
    className={className}
    columns={columns}
    rowKey={rowKey}
    dataSource={dataSource}
    pagination={pagination}
    loading={loading}
    onChange={onChange}
    onRow={onRow}
  />
);

export default index;
