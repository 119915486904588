import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

export default function ModalWrapper({ children, open, onCloseModal }) {
  return (
    <Modal
      open={open}
      closeIcon={false}
      onClose={onCloseModal}
      center
      forceRender
    >
      {children}
    </Modal>
  );
}
