import { useCallback, useEffect, useState } from "react";

import { Button } from "antd";
import { LeftCircleFilled } from "@ant-design/icons";

import CardCollectionDetail from "components/CardCollectionDetail";
import DonationsTable from "components/Table";
import TransactionsTable from "components/Table";
import Title from "components/Title";
import Block from "components/Block";

import { useRouter, Link } from "util/router";
import localAuth from "util/local-auth";

import { formatCurrency } from "util/helpers";

export const donationColumns = [
  {
    title: "",
    dataIndex: "logo_url",
    render: (img) => (
      <img src={img} alt="category img" className="img-category" />
    ),
  },  
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Charity Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "EIN",
    dataIndex: "ein",
    key: "ein",
  },
  {
    title: "Location",
    dataIndex: "city",
    key: "city",
  },
  {
    title: "G.S. Level",
    dataIndex: "gs_level",
    key: "gs_level",
    render: (gs_level) => `${gs_level}`
  },
  {
    title: "Donations (This Period)",
    dataIndex: "period_donations",
    key: "period_donations",
    render: (period_donations) => formatCurrency(period_donations, 1)
  },
  {
    title: "Total Donations",
    dataIndex: "total_donations",
    key: "total_donations",
    render: (total_donations) => formatCurrency(total_donations, 1)
  }
];

export const transactionsColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date"
  },
  {
    title: "Campaign ID",
    dataIndex: "campaign_id",
    key: "campaign_id",
  },
  {
    title: "Donation",
    dataIndex: "donation",
    key: "donation",
    render: (donation) => formatCurrency(donation, 1)
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Donation Period",
    dataIndex: "month",
    key: "month",
    render: (month, row) => `${month} ${row.year}`
  },  
];

export default function CollectionDetailPage() {
  const router = useRouter();
  const causeId = router.query.id;
  const [loadingDonations, setLoadingDonations] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [collection, setCollection] = useState(false);
  const [donations, setDonations] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [totalDonations, setTotalDonations] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);

  const getCauseCollection = useCallback(async () => {
      try {
        const response = await localAuth.getCauseCollection(causeId);

        if(response.success){
          setCollection(response.data);
        }
      } catch (error) {
        console.log({ error });
      }
  }, [causeId]);

  const getDonations = useCallback(async () => {
    try {
      setLoadingDonations(true);
      const response = await localAuth.getCauseCollectionDonations(causeId);

      if(response.success){
        setDonations(response.data.rows);
        setTotalDonations(response.data.total_items);
      }
      setLoadingDonations(false);
    } catch (error) {
      console.log({ error });
    }
}, [causeId]);  

const getTransactions = useCallback(async () => {
  try {
    setLoadingTransactions(true);
    const response = await localAuth.getCauseCollectionTransactions(causeId);

    if(response.success){
      setTransactions(response.data.rows);
      setTotalTransactions(response.data.total_items);
    }
    setLoadingTransactions(false);
  } catch (error) {
    console.log({ error });
  }
}, [causeId]); 

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCauseCollection();
      getDonations();
      getTransactions();
    }
    return () => {
      isMounted = false;
    };
  }, [getCauseCollection, getDonations]);

  const donationsTitle = `${totalDonations > 1 ? totalDonations + " " : ""} Charities`;
  const transactionsTitle = `${totalTransactions > 1 ? totalTransactions + " " : ""} Transactions`;

  return (
    <section className="container">
      <div style={{ paddingBottom: "32px" }}>
        <Link to={`/collections`}>
          <Button shape="round" icon={<LeftCircleFilled />} type="text">
            All Collections
          </Button>
        </Link>
      </div>

      <CardCollectionDetail {...collection} />

      <div style={{ padding: "32px 0" }}>
        <Block appearance="row">
          <Title text={donationsTitle} />
        </Block>        
        <DonationsTable
          columns={donationColumns}
          dataSource={donations}
          loading={loadingDonations}
        />
      </div>

      <div style={{ padding: "12px 0" }}>
        <Block appearance="row">
          <Title text={transactionsTitle} />
        </Block>        
        <TransactionsTable
          columns={transactionsColumns}
          dataSource={transactions}
          loading={loadingTransactions}
        />
      </div>      
    </section>
  );
}
