import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

export default function BreadcrumbNavigation({
  push = "/",
  prevRoute = "",
  currentRoute = "",
  appearance = false,
  additionalRoute = false,
  additionalPath = false
}) {
  return (
    <Breadcrumb
      separator=">"
      className={`Breadcrumb ${appearance ? `Breadcrumb__${appearance}` : ""}`}
    >
     {
       additionalRoute && (
        <Breadcrumb.Item>
        <Link className="Breadcrumb__Link" to={additionalPath}>
          {additionalRoute}
          </Link>
        </Breadcrumb.Item>
       )
     }
        <Breadcrumb.Item>
        <Link className="Breadcrumb__Link" to={push}>
          {prevRoute}
          </Link>
        </Breadcrumb.Item>
  
      <Breadcrumb.Item className="Breadcrumb__Link">
        {currentRoute}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}
