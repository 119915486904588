import React, { useEffect, useState } from "react";
import { Menu, Layout } from "antd";
import LogoInspired from "../../../assets/images/Logo.svg";
import { Link, useLocation } from "../../../util/router";
import { Redirect } from "react-router-dom";
import LogoLogout from "../../../assets/images/logout-1.svg";
const { Sider } = Layout;
const { SubMenu } = Menu;
const SideMenu = () => {
  const brand_id = localStorage.getItem("brand_id");

  const location = useLocation();
  const isActive = location.pathname;

  const [logout, setLogout] = useState(false);
  const handleLogout = () => {
    localStorage.clear();
    setLogout(true);
  };

  if (logout) {
    return <Redirect to={"/"} push={true} />;
  }

  return (
    <Sider
      style={{}}
      breakpoint="lg"
      collapsedWidth="0"
      className={brand_id > 0 ? "brand" : ""}
    >
      <Link to="/dashboard" className="logo__container">
        <img src={LogoInspired} alt="Inspired " className="logo-buyable" />
      </Link>

      <Menu className="sidebar" mode="inline">
        <Menu.Item
          key="/dashboard"
          className={isActive === "/dashboard" ? "active" : ""}
        >
          Dashboard
          <Link to="/dashboard" />
        </Menu.Item>
        {/*}
        <Menu.Item
          key="/orders"
          className={isActive === "/orders" ? "active" : ""}
        >
          Orders
          <Link to="/orders" />
        </Menu.Item>*/}
        {brand_id == 0 ? (
          <Menu.Item
            key="/brands"
            className={isActive === "/brands" ? "active" : ""}
          >
            Brands
            <Link to="/brands" />
          </Menu.Item>
        ) : null}
        <Menu.Item
          key="/stores"
          className={isActive === "/stores" ? "active" : ""}
        >
          Stores
          <Link to="/stores" />
        </Menu.Item>

        <SubMenu key="/causes" title="Causes">
          <Menu.Item key="/all">
            All <Link to="/all-charities" />
          </Menu.Item>
          <Menu.Item key="/charities">
            Charities <Link to="/charities" />
          </Menu.Item>
          <Menu.Item key="/collections">
            Collections <Link to="/collections" />
          </Menu.Item>
          <Menu.Item key="/projects">
            Projects <Link to="/projects" />
          </Menu.Item>
        </SubMenu>
        {/*}
        <Menu.Item
          key="/campaigns"
          className={isActive === "/companies" ? "active" : ""}
        >
          Campaigns
          <Link to="/campaigns" />
  </Menu.Item> */}
        <Menu.Item
          key="/consumers"
          className={isActive === "/consumers" ? "active" : ""}
        >
          Consumers
          <Link to="/consumers" />
        </Menu.Item>
        <Menu.Item
          key="/orders"
          className={isActive === "/orders" ? "active" : ""}
        >
          Orders
          <Link to="/orders" />
        </Menu.Item>
        <Menu.Item
          key="/payments"
          className={isActive === "/payments" ? "active" : ""}
        >
          Payments
          <Link to="/payments" />
        </Menu.Item>
        <Menu.Item
          key="/users"
          className={isActive === "/users" ? "active" : ""}
        >
          Users
          <Link to="/users" />
        </Menu.Item>
      </Menu>
      <div className="wrapper-log-out">
        <div className="log-out" onClick={handleLogout}>
          <img src={LogoLogout} alt="logout" />
          Logout
        </div>
      </div>
    </Sider>
  );
};

export default SideMenu;
