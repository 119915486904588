import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/Layout/Header";
import Title from "../components/Title";
import Block from "../components/Block";
import TableRoles from "../components/Table";
import localAuth from "../util/local-auth";
import { formatCurrency } from "util/helpers";
import { useRouter } from "../util/router";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (id) => `${id}`,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (name) => `${name}`,
  },
  {
    title: "Last Picked / Preferred Cause",
    dataIndex: "preferred_cause",
    key: "preferred_cause",
    render: (preferred_cause) => `${preferred_cause}`,
  },
  {
    title: "Transactions",
    dataIndex: "num_transactions",
    key: "num_transactions",
    render: (num_transactions) => `${num_transactions}`,
  },
  {
    title: "Period Donations",
    dataIndex: "period_donations",
    key: "period_donations",
    render: (period_donations) => formatCurrency(period_donations, 1),
  },
  {
    title: "Total Donations",
    dataIndex: "total_donations",
    key: "total_donations",
    render: (total_donations) => formatCurrency(total_donations, 1),
  },
];

export default function Users() {
  const router = useRouter();
  const [customers, setCustomers] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [pagination, setPagination] = useState({});
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const showConsumerDetails = (row) => {
    router.push(`/consumer/${row.id}`);
  };

  const getCustomers = useCallback(async (currentPage, pageSize) => {
    try {
      setLoading(true);
      const response = await localAuth.getCustomers(
        currentPage ? currentPage : 1,
        pageSize ? pageSize : defaultPageSize
      );
      console.log(response.data);
      if (response.success) {
        setPagination({
          current: response.data.current_page,
          pageSize: pageSize ? pageSize : defaultPageSize,
          total: response.data.total_items,
          onChange: function (page, pageSize) {
            setDefaultPageSize(pageSize);
            getCustomers(page, pageSize);
          },
        });

        setTotalCustomers(response.data.total_items);
        setCustomers(response.data.rows);
      }
      setLoading(false);
    } catch (error) {
      console.log({ error });
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCustomers();
    }
    return () => {
      isMounted = false;
    };
  }, [getCustomers]);

  const customersTitle = `${
    totalCustomers === 1 ? "1 Consumer" : totalCustomers + " Consumers"
  }`;

  return (
    <>
      <Header>
        <></>
      </Header>
      <section className="container">
        <Block appearance="row">
          <Title text={customersTitle} />
        </Block>
        <TableRoles
          className={"data__table"}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={customers}
          pagination={pagination}
          loading={loading}
          onRow={(row, rowIndex) => {
            return {
              onClick: (event) => {
                showConsumerDetails(row);
              }, // click row
            };
          }}
        />
      </section>
    </>
  );
}
